@import 'colors';
@import 'animation';

@font-face {
  font-family: "Gilroy";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url(../fonts/Gilroy-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url(../fonts/Gilroy-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url(../fonts/Gilroy-Bold.ttf) format("truetype");
}


.app {
  padding: 0.75vw;
  width: 100%;
  box-sizing: border-box;
}

body {
  font-family: "Gilroy" !important;
  //font-family: "Gilroy", sans-serif !important;
  text-shadow: none !important;
  height: 100% !important;
  overflow: auto !important;
}

html {
  font-family: "Gilroy" !important;
  //font-family: "Gilroy", sans-serif !important;
}

.header {
  // padding: 0 4% !important;
  // background-color: #383838 !important;
  background-color: white !important;
  padding-left: 10px !important ;
  padding-right: 0px; 
}

.lobby {
  margin-top: 100px;
  text-align: center;
  font-size: 1.25em;
}

.participants {
  display:flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 72px; 
  padding-top: 10px;
  //margin: 0px 4px;
  margin-left: 5px; 
  height: auto;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.cell-layout-child-4 {
  margin-left: 0px ;
  margin-right: 9px ;
  margin-top: 9px ;
}
.cell-layout-child-6 {
  margin-top: 9px ;
  margin-left: 0px ;
  margin-right: 9px ;
}
.cell-layout-child-8 {
  margin-top: 9px ;
  margin-left: 0px ;
  margin-right: 9px ;
}
.cell-layout-child-12 {
  margin-top: 9px ;
  margin-left: 0px ;
  margin-right: 9px ;
}
.cell-layout-child-15 {
  margin-top: 9px ;
  margin-left: 0px ;
  margin-right: 9px ;
}

.tag-new{
  color: #262626 !important;
  font-size: 12px !important;
  font-weight: 600;
  border-radius: 10px !important;
  margin-left: 10px !important;
  width: 50px;
  height: 20px !important;
}

.mic-off-icon path{
  fill: #ffffff;
  width: 14px;
}
.participant {
  box-sizing: border-box;
  position: relative;
  border-radius: 4px;
  // margin-top: 4px;
  // margin-right: 7px;
  // margin-bottom: 0.75vw;
  // width: 1080px;
  overflow: hidden;
  // height: 608px;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  display: inline-block;
  justify-content: center;
  align-self: center;
}

.identity {
  position: absolute;
  display: flex;
  align-items: center;
  left: 15px;
  bottom: 15px;
  color: white;
  // padding-left: 15px;
  box-sizing: border-box;
  // z-index: 10;
  font-size: 16px;
  font-weight: 600;
}
.student-graph{
  position: absolute;
  cursor: pointer;
  top: 15px;
  left: 15px;
  .ant-popover-title{
    font-size: 17px;
    font-weight: bold;
  }
  .ant-popover-content{
    width: 252px;
  }
}
.student-graph-icon path{
  // {
    fill: #ffffff;
    // width: 14px;
  // }
}
.student-graph-icon path:hover{
  fill: #82fff2;
}

.sync-icon-description{
  font-size: 14px;
  font-weight: 600;
  color: #262626;
  margin-left: 10px;
  margin-top: 3px;
}

.sync-icon-container{
  display: flex;
  padding: 5px 16px;
}

.sync-icon{
  display: flex;
  align-items: center;
}

.popover-help-icon-container{
  display: flex;
  align-items: center;
  margin-top: 3px;
}
.popover-help-icon{
  margin: auto;
  width: 14px;
  height: 14px;
  path{
    fill: #d2dfde !important
  }
}

.student-mic{
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mic-animation{
  position: absolute;
  right: 40px;
  bottom: 2px;
  padding-left: 0;
  padding-top: 10px;
  color: white;
  box-sizing: border-box;
  z-index: 10;
}

.identityy {
  position: absolute;
  right: 15px;
  bottom: 10px;
  color: white;
  // padding-left: 15px;
  box-sizing: border-box;
  // z-index: 10;
}

.student-video-expand-icon{
  position: absolute;
  right: 15px;
  top: 0px;
  color: white;
  box-sizing: border-box;
  margin-top: 8px;
  cursor: pointer;
}

.login-button-new {
  width: 75% !important;
  font-size: 20px !important;
  font-family: "Gilroy", sans-serif;
  font-weight: 500 !important;
}

.login-button {
  font-family: "Gilroy", sans-serif;
  text-shadow: none !important;
}
.ots-start-button {
  background-color: #8fb594 !important;
  font-family: "Gilroy", sans-serif;
  border-color: #8fb594 !important;
  float: right !important;
  width: 150px;
  font-weight: 600 !important;
  text-shadow: none !important;
  color: black !important;
}

.ots-test-button {
  background-color: #d5d5d5 !important;
  font-family: "Gilroy", sans-serif;
  border-color: #d5d5d5 !important;
  float: right !important;
  width: 150px;
  font-weight: 600 !important;
  text-shadow: none !important;
  color: black !important;
}

.ots-manage-button {
  background-color: #8fb594 !important;
  font-family: "Gilroy", sans-serif;
  border-color: #8fb594 !important;
  float: right !important;
  width: 150px;
  font-weight: 600 !important;
  text-shadow: none !important;
  color: black !important;
}
.test-next-button {
  background-color: #8fb594 !important;
  font-family: "Gilroy", sans-serif;
  border-color: #8fb594 !important;
  font-weight: 500 !important;
  // float: right !important;
  // width: 150px;
  text-shadow: none !important;
  color: black !important;
}

.activate-ots-button {
  font-family: "Gilroy", sans-serif !important;
  text-shadow: none !important;
  font-weight: 500 !important;
}
.logout-button {
  font-family: "Gilroy", sans-serif;
  text-shadow: none !important;
  font-size: 1.2vw !important;
  font-weight: 500 !important;
}

.craete-ots-button {
  font-family: "Gilroy", sans-serif;
  text-shadow: none;
}

.login-side-image-heading {
  position: absolute;
  color: white;
  padding-left: 15px;
  font-family: "Gilroy", sans-serif;
  top: 100px;
  left: 150px;
  z-index: 10;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: 2.2px;
  text-align: left;
  color: #ffffff;
}

.login-side-image-heading-one {
  font-size: 3vw;
  font-weight: 700;
  margin: 0;
}
.login-side-image-heading-two {
  font-size: 1.7vw;
  font-weight: 300;
  margin: 0;
}

@media (max-width: 1048px) {
  .login-side-image-heading-one {
    font-size: 25px;
  }
  .login-side-image-heading-two {
    font-size: 16px;
  }
}

@media (min-width: 1045px) and (max-width: 1146px) {
  .edit-detail-message{
    padding: 0px 5px !important;
  }
  .edit-detail-timeline{
    padding-right: 0px !important;
  }
}

.cross-icon-modal {
  width: 25px;
  position: absolute;
  right: 15px;
  top: 10px;
  color: white;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 100;
  margin-top: 4px;
}

.back-icon-modal {
  width: 19px;
  position: absolute;
  left: 10px;
  top: 10px;
  color: white;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 100;
}

video {
  display: block;
  box-shadow: 0px 0px 15px 5px #ddd;
  // width: 560px;
  width: 100%;
  height: 100%;
  // height: 248px;
  background-color: black;
  // -webkit-transform: scale(-1, 1);
  // transform: scale(-1, 1);
}

.participant {
  .audio {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.participant {
  .video {
    position: absolute;
    // width: 560px;
    // height: 315px;
    width: 100%;
    height: 100%;
  }
}

.join-button {
  // background-color: #fe019a;
  background-color: #d1b68c;
  font-weight: bold;
  display: block;
  margin: 0 auto !important;
  color: white !important;
  border: none;
  padding: 15px;
  border-radius: 5px;
  width: 200px;
  cursor: pointer;
  font-size: 1em;
}

.join-button:hover {
  // background-color: #fe019a;
  background-color: #fff;
  font-weight: bold;
  display: block;
  margin: 0 auto !important;
  color: black !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  padding: 15px;
  border-radius: 5px;
  width: 200px;
  cursor: pointer;
  font-size: 1em;
}

.sync-button {
  // background-color: #fe019a;
  background-color: #9ca3af;
  font-weight: 500;
  display: block;
  color: white !important;
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 140px;
  cursor: pointer;
  font-size: 1em;
}

.sync-button:hover {
  // background-color: #fe019a;
  background-color: #fff;
  font-weight: 500;
  display: block;
  color: black !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  padding: 9px;
  border-radius: 5px;
  width: 140px;
  cursor: pointer;
  font-size: 1em;
}

.video-sync {
  text-align: center;
  font-size: 1.25em;
}

button:disabled {
  opacity: 0.4;
  cursor: initial;
}

.leave-room {
  margin-top: 25px;
  margin-left: 0;
}

input {
  padding-bottom: 5px;
  border: none;
  outline: none;
  border-bottom: 1px solid #555;
  // margin-bottom: 25px;
  width: 200px;
  font-size: 1em;
  text-align: center;
  border-radius: 0;
}

// @media (max-width: 1048px) {
//   .app {
//     padding: 1vw;
//   }

//   .participant {
//     width: 47vw;
//     margin-top: 1vw;
//     margin-bottom: 1vw;
//     margin-left: 2vw;
//     height: 42vh;
//   }

// @media (orientation:landscape) {
//   .participant {
//     width: 48vw;
//     margin-left: 1vw;
//     height: 96vh;
//   }
// }

// }

.teacher-home-class {
  border-style: solid;
  border-color: green;
  border-width: 1px;
  border-radius: 5px;
  // height: '200px';
  width: 700px;
  background-color: #fbfbfb;
  font-family: sans-serif;
  font-size: large;
  margin: 0 auto;
}

.dominantSpeaker:before {
  z-index: 20;
  border: 5px solid #ff7878;
  content: "";
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  border-radius: 8px;
}

.avControl {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 20;
  color: white;
  opacity: 0.6;
  cursor: pointer;
  font-size: 1rem;
}

.avControl:hover {
  opacity: 1;
}

.fa-microphone-slash,
.fa-video-slash {
  color: red;
}

// header css
.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}
.logo {
  float: left;
  width: 200px;
  height: auto;
  padding: 18px 18px 18px 10px;
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  // margin: 16px 0 16px 24px;
}

.signup-button {
  margin-left: auto;
  align-self: center;
  background-color: #8fb594 !important;
  border-color: transparent !important;
  border-radius: 0 !important;
  width: 100px;
  margin-top: 15px;
}
.signup-button:hover {
  color: black !important;
  margin-left: auto;
  align-self: center;
  background-color: #8fb594 !important;
  border-color: transparent !important;
  border-radius: 0 !important;
  margin-top: 15px;
}

// .logout-button:hover {
//   color: black !important;
//   margin-left: auto;
//   align-self: center;
//   background-color: #8fb594 !important;
//   border-color: transparent !important;
//   border-radius: 0 !important;
// }
// .logout-button {
//   margin-left: auto;
//   align-self: center;
//   background-color: #8fb594 !important;
//   border-color: transparent !important;
//   border-radius: 0 !important;
//   width: 100px;
//   margin-top: 15px;
// }

.logo-image {
  width: 100%;
  margin-left: 4%;
  // margin-bottom: 14px;
}
.logout-button-box {
}

.bg-signup {
  .ant-image {
    width: 100% !important;
    height: 100% !important;
  }
}

.bg-signup {
  // background: url(../img/bg-signup.png);
  background: url(../img/background.jpg);
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  // position: fixed;
  height: auto;
  min-height: 100vh;
  position: relative;
  // height: 625px;
}

.bg-home {
  // background: url(../img/bgHome.jpg);
  background-color: #262626;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: auto;
  min-height: 100vh;
  position: relative;
}

.avControl {
  .fa-microphone {
    width: 20px;
  }
}

.login-form {
  padding: 45px 80px;
}
.login-form-forgot {
  font-family: "Gilroy", sans-serif;
  font-size: 1.2vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8fb594;
}
.login-heading {
  font-family: "Gilroy", sans-serif;
  font-weight: 700;
  font-size: 3vw;
  color: #383838;
}
.login-side-image {
  padding: 45px 45px 100px 100px;
}

@media (min-width: 456px) and (max-width: 555px) {

  // .login-side-image-heading-one {
  //   font-size: 3vw;
  // }
  // .login-side-image-heading-two {
  //   font-size: 1.7vw;
  // }
  .login-side-image-heading {
    left: 60px;
  }
}
@media (max-width: 455px) {
  .login-side-image {
    padding: 40px 45px 50px 50px;
  }
  .login-side-image-heading-one {
    font-size: 17px;
  }
  .login-side-image-heading-two {
    font-size: 11px;
  }
  .login-side-image-heading {
    left: 60px;
    top: 70px;
  }
}
.login-checkbox {
  color: #383838;
  font-size: 1.2vw !important;
  .ant-checkbox .ant-checkbox-inner {
    width: 25px;
    height: 25px;
    background-color: #d5d5d5;
    border-color: #d5d5d5;
    color: #383838;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    width: 25px;
    height: 25px;
    background-color: #d5d5d5;
    border-color: #d5d5d5;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    width: 25px;
    height: 25px;
    background-color: #8fb594;
    border-color: #8fb594;
  }
}
.teacher-heading {
  font-family: "Gilroy", sans-serif;
  font-size: 1.7vw;
  color: #383838;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

@media (min-width: 326px) and (max-width: 555px) {
  .teacher-heading {
    font-size: 2.7vw;
  }
  .teacher-heading-detail {
    font-size: 2.5vw !important;
  }
  .login-heading {
    font-size: 4vw;
  }
  .login-checkbox {
    font-size: 3.2vw !important;
  }
  .login-form-forgot {
    font-size: 3.2vw !important;
  }
}
.teacher-heading-detail {
  font-family: "Gilroy", sans-serif;
  font-size: 1.2vw;
  color: #383838;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.time-zone {
  width: 75%;
    // .css-yk16xz-control {
    //   height: 6.5vh;
    // }
}
@media (max-width: 575px) {
  .time-zone {
    width: 100%;
  }
}
.input-password {
  .ant-input-password {
    height: 55px;
    background-color: #d5d5d5;
    border: none;
  }
  .ant-input {
    text-align: left;
  }
}
.input-password {
  .ant-input {
    // height:40px;
    background-color: #d5d5d5;
    // border: none;
  }
}
.video-player {
  width: 426px !important;
  height: 240px !important;
}

.class-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.home-footer {
  background: none;
  // padding: 0;
  // position: "unset";
  // position: "unset";
  // width: 100vw;
  // height: 40;
  // bottom: 0;
  // overflow: "hidden";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  justify-self: flex-end;
}

.login-button-sync {
  background-color: #eceeee !important;
  border-color: #eceeee !important;
  color: black !important;
}
.login-button-sync:active {
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  background-color: #8fb594 !important;
  color: white !important;
}
.footer-button {
  width: 150px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Gilroy";
  text-shadow: none !important;
  float: right !important;
}

.view-button {
  font-family: "Gilroy", sans-serif;
  text-shadow: none !important;
}

.view-button-inside{
  box-shadow: none !important;
  width: 105px !important;
  height: 48px !important;
  border-radius: 27px !important;
  font-weight: 600 !important;
}

.ots-start-button-box {
  margin-bottom: 10px;
  margin-right: 25px;
  font-family: "Gilroy", sans-serif;
  text-shadow: none !important;
}

.ots-heading {
  font-family: "Gilroy", sans-serif;
  font-size: 2.2vw;
  font-weight: bold;
}

.analytic-banner {
  .flex-wrapper-item-50:nth-child(even) {
    padding: 8px 0px 8px 8px;
  }
  .flex-wrapper-item-50:nth-child(odd) {
    padding: 8px 8px 8px 0px;
  }
}

.audience-main{
  .audience-map:nth-child(even){
    padding:8px 0px 8px 8px
  }
  .audience-map:nth-child(odd){
    padding:8px 8px 8px 0px;
  }
}

.top-performer{
  .your-top-three-card:nth-child(even){
    padding:8px 0px 8px 8px
  }
  .your-top-three-card:nth-child(odd){
    padding:8px 8px 8px 0px;
  }
}
.demo-email-input{
  width: 75% !important;
  text-align: left;
}
@media (min-width: 768px) and (max-width: 1024px) {
//iPad
  .cell-layout-child-4 {
    margin-left: 0px !important;
    margin-right: 9px !important;
    margin-top: 9px !important;
  }
  .cell-layout-child-6 {
    margin-top: 9px !important;
    margin-left: 0px !important;
    margin-right: 9px !important;
  }
  .cell-layout-child-8 {
    margin-top: 9px !important;
    margin-left: 0px !important;
    margin-right: 9px !important;
  }
  .cell-layout-child-12 {
    margin-top: 9px !important;
    margin-left: 0px !important;
    margin-right: 9px !important;
  }
  .cell-layout-child-15 {
    margin-top: 9px !important;
    margin-left: 0px !important;
    margin-right: 9px !important;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
//mobile
.directcoach-time-section-input{
  margin-top: 20px;
}
.direct-coach-date-time{
  padding: 0px !important;
  .ant-picker-calendar-full{
    //height: 52vh !important;
  }
}
.message-modal-heading{
  padding: 10px 10px 0px 10px !important;
}
.message-container{
  width: 100%;
  height: 60px;
}
.student-video-expand-icon{
  display: none;
}
  .cell-layout-child-4 {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 9px !important;
  }
  .cell-layout-child-6 {
    margin-top: 9px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .cell-layout-child-8 {
    margin-top: 9px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .cell-layout-child-12 {
    margin-top: 9px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .cell-layout-child-15 {
    margin-top: 9px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .demo-email-input{
    width: 100% !important;
  }
  .coaching-buttons{
    flex-direction: column;
  }
  .ots-footer{
  display: unset !important;
  padding-right: 0px !important;
  }
  .button-section-demo{
    margin-left: 0% !important;
    margin-top: 2%;
  }
  .audience-main{
    .audience-map:nth-child(even){
      padding:10px 0px 10px 0px !important
    }
    .audience-map:nth-child(odd){
      padding:10px 0px 10px 0px !important
    }
  }
  
  .top-performer{
    .your-top-three-card:nth-child(even){
      padding: 10px 0px 10px 0px;
    }
    .your-top-three-card:nth-child(odd){
      padding:10px 0px 10px 0px
    }
  }
  .analytic-banner {
    .flex-wrapper-item-50:nth-child(even) {
      flex: 100%;
      padding: 4px 0px 4px 0px;
    }
    .flex-wrapper-item-50:nth-child(odd) {
      flex: 100%;
      padding: 4px 0px 4px 0px;
    }
  }

  .participants {
    min-height: 100vh;
  }
  .login-side-image {
    padding: 50px 50px 10px 50px;
  }
  .ots-heading {
    font-size: 4.2vw;
  }
  .ots-input-heading {
    font-size: 3.3vw !important;
  }
  .ots-name-select {
    font-size: 3.2vw !important;
  }
  .ots-datetimepicker {
    font-size: 3.2vw !important;
  }
  .ots-date-text {
    font-size: 2.3vw !important;
  }
  .field-estimate {
    font-size: 3.6vw !important;
    margin-left: 10px !important;
  }
  .logout-button {
    font-family: "Gilroy", sans-serif;
    text-shadow: none !important;
    font-size: 3.2vw !important;
    font-weight: 500 !important;
    background-color: "#8fb594" !important;
    border-color: "#8fb594" !important;
    width: 100px !important;
    height: 40px !important;
    color: "black" !important;
  }
  .login-button-new {
    width: 100% !important;
  }
  .login-form {
    padding: 50px 40px;
  }
  .ots-start-button {
    width: 95px;
    font-size: 3vw !important;
  }
  .ots-start-button-box {
    margin-bottom: 0px;
    margin-right: 0px;
  }
  .ots-datetimepicker {
    #basic_startTime {
      font-size: 3.2vw !important;
    }
    #basic_classDate {
      font-size: 3.2vw !important;
    }
    #basic_endTIme {
      font-size: 3.2vw !important;
    }
  }
  .timezone-select {
    font-size: 3.2vw !important;
  }
  .ots-student-input {
    font-size: 3.2vw !important;
  }
  .ots-currency-select {
    font-size: 3.2vw !important;
  }
  .activate-ots-button {
    font-size: 3.2vw !important;
    margin-bottom: 15px;
  }
  .testscreen-heading{
      font-size: 20px !important;
      padding: 10% 9% !important;
  }
  .test-mainscreen{
    padding: 0% 5% !important;
  }
  .test-icon-img{
    width: 60px !important;
    height: auto !important;
  }
  // .divider-line{
  //   margin: 0px 0px 60px 0px !important;
  // }
  .title-testicons{
    font-size: 10px !important;
  }
  .ots-manage-button{
    width: 100px !important;
    font-size: 10px !important;
    margin: 4% 0%;
  }
  .ots-test-button{
    width: 100px !important;
    font-size: 10px !important;
    margin: 4% 0%;
  }
  .ots-message{
    font-size: 11px !important;
  }

  .ant-layout-footer {
    padding: 2px 2px !important;
    font-size: 10px !important;
  }
  .video-player{
    width: 340px !important;
    height: 192px !important;
  }
  .participant{
    width: 316px !important;
    height: 178px !important;
  }
  .footer-button{
    position: relative;
    // z-index: 1;
    // width: 150px !important;
    margin-right: 2px !important;
  
  }
  .userName{
    //display: none !important;
  }
  .ant-btn.ant-btn-primary.logout-button{
    display: none !important;
  }
  .sideNavli{
    font-size: 16px;
    color: #262626;
    font-weight: 600;
    padding-top: 1px;
    margin-left: 10%; 
  }
  .ant-menu-inline .ant-menu-item-selected::after{
    display: none !important;
  }
  .menuItem{
    height: 60px !important;
    margin: 0px !important;
    justify-content: center !important;
  }
  .audience-section{
    display: flex;
    flex-direction: column;
  }
  .audience-map{
    width: 100% !important;
  }
  .card-cover-audience{
    &-container{
      padding:8px !important;
    }
    &-image-title{
      top: 10px !important;
      left: 16px !important;
      font-size: 11px !important;
    }
    &-count{
      font-size: 18px !important;
    }
  }
  .views-graph{
    flex-direction: column;
    &-buttons{
      display: flex;
      flex-direction: column;
    }
    &-label-container{
      flex-direction: column;
    }
  }
  .menuIconDiv{
    display: none;
  }
  .PlusDiv{
    display: none;
  }
  .logo-header{
    
    display: flex !important;
    height: 40px;
    width: 40px;
    margin-top: 12px;
    margin-left: 16px;
  }
  .sider{
    display: none;
  }
  .avatar{
    width: 45px !important;
    height: 45px !important;
  }
  .bottom-navigation{
    display: flex !important;
  }
  .flex-wrapper-item-50{
    padding: 4px !important;
  }
  .ant-card-meta-title{
    margin-bottom: 4px;
  }
  .ant-layout.site-layout-background{
    //padding: 0px 16px 50px 16px !important;
  }
  .main-page-container{
    padding: 0px 16px 50px 16px !important;
  }
  .main-coaching-container{
    padding: 10px 18px !important;
    overflow: scroll;
  }
  .audience-map{
    padding: 10px !important;
  }
  .your-top-three-main{
    display: block !important;
  }
  
  .your-top-three-card{
    width: 100% !important;
  }
  // .views-graph{
  //   display: block !important;
  //   justify-content: none !important;
  // }
  .views-graph-label-container{
    flex-direction: row;
    margin-top: 34px;
  }
  .views-graph-buttons{
    flex-direction: row !important;
  }
  .avatar{
    width: 32px !important;
    height: 32px !important;
  }
  .content-profile{
    margin-right: 16px !important;
  }
  .page-inner-title{
    margin-top: 32px !important;
    margin-bottom: 24px !important;
    font-size: 24px !important;
  }
  .your-top-three{
    margin-bottom: 27px !important;
    margin-top: 48px !important;
  }
  .bottom-navigation-menu{
    margin-top: 6px !important;
    padding: 0px !important;
  }

    .card-cover-detail{
      .ant-card-meta-title{
        margin-bottom: 1px !important;
      
      }
    }
  .content-profile{
    .ant-popover-content{
      margin-right: 9px !important;
    }
  }

  .total-earnings-graph{
    margin-top:42px !important;
    margin-bottom: 0px !important;
    flex-direction: column;
    &-left{
      width:100% !important;
      margin-bottom: 45px;
    }
    &-right{
      width: 100% !important;
      padding: 0px !important;
      //margin-bottom: 50px;
    }
  }
  .line-divider-statement{
      margin-bottom: 20px !important;
      margin-top: 20px !important;
  }

  .line-divider{
    margin-bottom: 32px !important;
    margin-top: 47px !important;
  }

  .earnings-weekly-graph{
    width: 24% !important;
  }
  .earnings-main{
    margin-top: 42px !important;
  }

  // .simple-classes-card img{
  //   width: 88px !important;
  // }
  .simple-classes-card{
    display: flex;
    flex-direction: column;
    align-items: unset !important;
    width:100% !important;
    &-content{
    //margin: 0px 0px 0px 20px !important;
      display: flex;
      flex-direction: column !important;
      &-title{
        font-size: 16px !important;
        margin-top: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width:85%;
      }
      &-description{
        font-size: 16px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width:85%;
      }
    }
    &-upper{
      display: flex;
      flex-direction: row;
      width: unset !important;
    }
    &-lower{
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      padding: 12px 30px 12px 6px;
      margin-left: unset !important;
      margin-top: unset !important;
      width: 100% !important;
    }
    &-content-views{
      margin-left: -8px !important;
    }
    &-content-amount{
      margin-left: 20px !important;
      margin-right: 30px !important;
    }
  }

  .simple-classes-calendar-card{
    display: flex;
    justify-content: space-between;
    // flex-direction: column;
    align-items: unset !important;
    width:100% !important;
    &-content{
    // margin: 0px 0px 0px 20px !important;
      display: flex;
      flex-direction: column !important;
      &-title{
        font-size: 16px !important;
        margin-top: 16px;
      }
      &--description{
        font-size: 16px !important;
      }
    }
    &-upper{
      display: flex;
      flex-direction: row;
      width: unset !important;
    }
    &-lower{
      margin-top: 18px;
      // display: flex;
      // justify-content: space-between;
      // padding: 17px 8px 17px 30px;
      // margin-left: unset !important;
      // margin-top: unset !important;
      // width: 100% !important;
    }
    // &-content-views{
    //   margin-left: -8px !important;
    // }
    // &-content-amount{
    //   margin-left: 20px !important;
    //   margin-right: 30px !important;
    // }
  }
  .page-inner-title-main{
    justify-content: space-between;
  }
  .classes-button{
    margin-top: 10px !important;
    width: 137px !important;
  }


  .classes-card-image{
    width:100% !important;
    img{
    width:100% !important;
    height: 120px;
    border-radius: 8px 8px 0px 0px !important;
    }
  }

  .classes-calendar-card-image{
    width:35% !important;
    img{
    width:100% !important;
    height: 72px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;    }
  }

  .classes-card-main{
    flex-direction: column;
  }
  .classes-calendar-card-main{
    display: flex;
  }
  .classes-status-bar{
    position:absolute;
    top:0;
    right:0;
    margin-top:15px;
  }
  .status-bar-main{
    width: 72px !important;
  }
  .classes-tab-heading-main{
    margin-top: 24px !important;
  }
  .classes-tab{
    .ant-tabs-nav-wrap{
      display: flex !important;
      justify-content: center;
    }
  }
  
  .classes-calendar{
    .ant-picker-cell-selected{
      .date-fullcell-responsive{
        background-color: #1d615a;
        width: 35px;
        border-radius: 50%;
        margin: auto;
        color: #fff;
        .calendar-circle{
          display: none !important;
        }
      }
      
    }
  }
  
  .classes-full-calendar{
    .ant-picker-cell-selected{
      .date-fullcell-responsive{
        background-color: #1d615a;
        width: 35px;
        border-radius: 50%;
        margin: auto;
        color: #fff;
        .calendar-circle{
          display: none !important;
        }
      }
      
    }
    thead{
      display: contents !important;
    
      tr{
        border-top: 1px solid #e8efee;
        border-bottom: 1px solid #e8efee;
        
      }
      th{
        opacity: 0.5;
        font-family: Gilroy;
        font-size: 14px;
        font-weight: 600;
        color: #262626;
        padding: 10px 12px 10px 10px !important;
      }
    }
  }

  .calendar-scroll-classes{
    &-card{
      width: 97%;
      margin-left: 4px;
    }
  }

  .ant-picker-calendar-full .ant-picker-panel{
    padding: 0px 0px 10px 0px;
  }
  .referrals{
    &-content{
      display: unset !important;
    }
    &-left{
      width: 100% !important;
    }
    &-right{
      width: 100% !important;
      margin-top: 60px;
    }

  }
  .nothing-screen{
    width: unset !important;
    margin: 20% 0% 0% 0% !important;
  }
  // .flex-wrapper-coaching {
  //   &-item-50 {
  //     flex: 100%;
  //   }
  // }
  .coaching-card{
    width: 100%;
    &-component{
      width: 100% !important;
      height: 216px !important;
      img{
        width:100% !important;
      }
    }
    &-container{
      margin-bottom: 28px;
    }
    &-image{
      padding-right: 0px !important;
      &-upper{
        width: 90% !important;
      }
    }
    &-timmer{
      width: 100% !important;
    }
    &-date{
      margin-top: 25px !important;
    }
    &-time{
      margin-top: 16px !important;
    }
  }
  .card-cover-overlay-coaching{
    width: 100% !important;
    height: 216px !important;
  }
  .coaching-tab{
    .ant-tabs-tab{
      margin: 0px 28px 0px 0px !important;
    }
  }

  .ots-input-res{
    margin-top: 22px;
  }
  .login-input{

    .ant-input-number-input::placeholder {
      font-size: 12px !important;
      line-height: 30px !important;
    }
    .ant-input-number-input:placeholder-shown{
      padding-top: 6px;
    }
  }
  .test-home-screen{
    overflow-y: scroll;
    padding: 30px 0px;
    //height: 100% !important;
      &-container{
        width: 100% !important;
        margin: auto;
      }
      &-main{
        display: unset !important;
        justify-content: unset !important;
        margin: auto;
        width: unset !important;
      }
      &-box{
        margin-left: unset !important;
        margin-top: 28px !important;
      }
      &-title{
        padding-top: 20px;
      }
  }

  .test-audio-screen{
      &-container{
        width: 100% !important;
      }
      &-main{
        display: unset !important;
        justify-content: unset !important;
        margin: auto;
        width: unset !important;
      }
      &-box{
        margin-left: unset !important;
        margin-top: 28px !important;
        width: 290px !important;
      }
  }

  .waiting-screen{
    &-bottom{
      flex-direction: column;
      height: 140px !important;
      &-middle{
        width: 100% !important;
        margin-top: 70px !important;
        &-icons{
          width: 30px !important;
          height: 30px !important;
        }
        &-mobile{
          display: flex;
          justify-content: center;
          //margin: auto;
          &-button{
            width: 75px;
            height: 40px !important;
            border-radius: 4px !important;
            // background-color: #d32f2f !important;
            // border-color: #d32f2f !important;
            display: flex;
            justify-content: center;
            cursor: pointer;
            margin-left: 19px;
            margin-right: 9px;
            color: #fff !important;
            font-size: 16px !important;
              font-weight: 600 !important;
            &-span{
              font-family: Gilroy;
              font-size: 16px;
              font-weight: 600;
              text-align: center;
              color: #fff;
              margin: auto;
            }
          }
        }
      }
      &-right{
        display: none !important;
      }
    }
    &-bottom-left{
      width: 100% !important;
    }
  }

  .middle-icon{
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .left-icon{
    margin-left: 12px !important;
  }

  .collapsed-footer-video-overlays{
    pointer-events: none;
    display: none;
    svg{
      display: none;
    }
  }
  .waiting-screen-video-collapsed:hover{
    .collapsed-footer-video-overlays{
      background-color: transparent !important;
      display: none !important;
    }
  }
  .create-direct-coach{
    flex-direction: column;
    // padding: 16px;
    height: calc(100vh - 70px) !important;
    overflow-y: auto;
    &-left{
      width: 100% !important;
      background-color: #fff !important;
      padding: 0px 16px;
      &-heading{
        width: 100% !important;
        margin-top: 24px !important;
      }
    }
    &-right{
      width: 100% !important;
      height: calc(100vh - 180px) !important;
      margin-top: 32px;
      &-bottom{
        width: 100% !important;
      }
    }
    &-logo{
      display: none;
    }
    .direct-coach-heading-component{
      line-height: normal;
      font-size: 24px;
      color: #262626 !important;
    }
    .dc-class-home{
      width: 100% !important;
      padding: 0px 16px;
      &-input{
        width: 100% !important;
        margin-top: 24px !important;
      }
      &-card-container{
        padding-bottom: 90px !important;
      }
    }
    .dc-bottom-bar{
      height: 63px !important;
    }
    .direct-coach-student-values-square{
      margin-left: 8px !important;
    }
  }
  .direct-coach-confirm{
    padding: 0px 16px;
    margin-top: 80px !important;
    &-card{
      width: 100% !important;
      &-component{
        width: 100% !important;
      }
      &-overlay{
        width: 100% !important;
      }
    }
    &-detail{
      width: 100% !important;
    }
}
  .direct-coach-description-component{
    color: #262626 !important;
  }
  .direct-coach-student-values{
    .square-small{
      display: none;
    }
  }
  .direct-coach-cost{
    margin-top: 28% !important;
    width: 90%;
    &-earnings{
      width: 100% !important;
      margin-top: 32px !important;
      &-wrapper{
        margin-bottom: 24px;
      }
    }
 
  }
  .direct-coach-student{
    //margin-top: 28% !important;
  }
  .dc-bottom-bar{
    &-wrapper{
      &-next{
        width: 83px !important;
        height: 40px !important;
      }
    }
  }

.demo-direct-coach{
  width: 100% !important;
  &-wrapper{
    width:80% !important;
    //margin: auto;
  }
}
  //asad
}


@media (min-width: 3320px) and (max-width: 5420px) {
  .testscreen-heading{
    font-size: 70px !important;
  }
  .test-icon-img{
    width: 187px !important;
  }
  .title-testicons{
    font-size: 32px !important;
  }
  .volume-heading{
    font-size: 40px !important;
  }
  .volume-subheading{
    font-size: 26px !important;
  }
  .network-img{
    width: 187px !important;
  }
}

.ots-input-heading {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 1.3vw;
  margin-bottom: 5px;
}

.field-estimate {
  margin-top: 25px;
  font-size: 1.6vw;
  font-weight: bold;
  margin-left: 15px;
}

.ots-home {
  padding: 20px 50px;
}

.time-left {
  width: 217px;
  height: 63px;
  clip-path: polygon(
    30% 0%,
    70% 0%,
    100% 0,
    100% 45%,
    94% 75%,
    94% 47%,
    0% 45%,
    5% 0
  );
  background-color: #feb264;
  position: absolute;
  right: -10px;
  top: -18px;
  color: #383838;
  text-align: center;
  font-size: medium;
}

.tag-full {
  width: 73px;
  height: 15px;
  background-color: #9ca3af;
  position: absolute;
  transform: rotate(-35deg);
  top: 8px;
  left: -15px;
  z-index: 1;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timezone-select {
  font-size: 1.2vw;
  .css-yk16xz-control {
    // background-color: #d5d5d5;\
    border:1px solid rgb(153 153 153 / 37%);
    border-radius: 0px;
  }
  .css-1hwfws3 {
    height: 40px;
  }
}

.price-input {
  .ant-input {
    background-color: #d5d5d5;
  }
  .ant-input-number-input {
    text-align: start;
    margin-top: 5px;
  }
}
.ant-input-number-input {
  height: 40px;
  &::placeholder {
    font-size: 1.2vw !important;
    line-height: 1.2vw !important
  }
}

@media (min-width: 768px ) {
  .ots-name-select , .ant-select-selection-placeholder{
    font-size: 1.2vw !important;
    line-height: 1.2vw !important
  }
  .ots-datetimepicker {
    font-size: 1.2vw !important;
    #basic_startTime {
      font-size: 1.2vw !important;
    }
    #basic_classDate {
      font-size: 1.2vw !important;
    }
    #basic_endTIme {
      font-size: 1.2vw !important;
    }
  }
  .ots-currency-select {
    font-size: 1.2vw;
  }
  .ots-student-input {
    font-size: 1.2vw;
  }
  .activate-ots-button {
    font-size: 1.2vw;
  }
}

@media (min-width: 768px) and (max-width:850px){
  //mobile landscape
  .calendar-timeline{
    // padding-top: 10%;
    // width: 100%;
    .calendar-scroll-classes {
      // padding: 0px;
      //max-height: 100vh !important;
       max-height: calc(100vh - 233px) !important;
    }
  }
}
@media (min-width: 851px) and (max-width:1025px){
  //ipad portrait
  .calendar-timeline{
    padding-top: 10%;
    width: 100%;
    .calendar-scroll-classes {
      padding: 0px;
      //max-height: 100vh !important;
       max-height: calc(100vh - 333px) !important;
    }
  }
}
.edit-detail-support-button, button.edit-detail-support-button:hover,
button.edit-detail-support-button:focus{
 width: 156px !important;
 height: 42px !important;
 border-color: #d2dfde;
 color: #262626;
 font-weight: 600 !important;
}

@media (min-width: 768px) and (max-width:1025px){
  //ipad
 .edit-detail-timeline{
  padding-right: 0px !important;
}

  .field-estimate{
    margin-left: 6px !important;
  }
  .coaching-card-container{
    margin-bottom: 8%;
  }
  .ant-picker-body th{
    padding: 10px 12px 10px 12px !important;
  } 
  .calendar-month-title{
    margin-left: 0px !important;
  }
  // .calendar-timeline{
  //   padding-top: 10%;
  //   width: 100%;
  //   .calendar-scroll-classes {
  //     padding: 0px;
  //     //max-height: 100vh !important;
  //      max-height: calc(100vh - 333px) !important;
  //   }
  // }
  .calendar-today-button{
    margin-left: auto !important;
  }
  .calendar-timeline{
    padding-top: 2%;
    width: 100% !important;
  }
  .calendar{
    flex-direction: column-reverse;
  }
  .calendar-header{
    padding: 8px;
    border: none !important;
    // &-arrows{
    //   display: none;
    // }
    &-down-arrow{
      align-self: center;
      margin-left: 10px;
    }
    &-responsive-arrow{
      display: flex !important;
      margin-top: -5px;
    }
  }
  .calendar-month-title{
    //width: 170px !important;
    font-size: 20px !important;
  }
  .calendar-view{
    width: 100% !important;
  }
  

  .classes-calendar{  

    .ant-picker-cell-selected{
      .date-fullcell-responsive{
      background-color: #1d615a;
      width: 40px;
      border-radius: 50%;
      margin: auto;
      color: #fff;
      .calendar-circle{
        display: none !important;
      }
      }
  
    }

   
    .ant-picker-body{
      text-align: center;
    }
    .ant-picker-cell{
      //padding: 8px 0px 0px 0px!important;
    .ant-picker-calendar-date{
          height: 35px !important;
          min-width: 35px !important;
          width: 35px !important;
          border: transparent !important;
          display: flex !important;
          border-radius: 25px !important;
          justify-content: center;
          padding: 7px 8px 0 !important;
          background-color: rgb(63, 59, 59);
          font-family: Gilroy;
          font-size: 14px ;
          font-weight: 600;
    }

    .date-fullcell-responsive{
      height: 36px !important;
      min-width: 36px !important;
      width: 36px !important;
      flex-direction: column;
      // border: transparent !important;
      display: flex !important;
      border-radius: 25px !important;
      // justify-content: center;
      padding: 7px 8px 0 !important;
      // background-color: #fff;
      font-family: Gilroy;
      font-size: 14px ;
      font-weight: 600;    }
    }
    thead{
      display: none;
    }
    tbody{
    display: flex;
    overflow: scroll;
    height: 100%;
    //width: calc(100vw - 30px);
    td{
      padding: 0px 10px 0px 10px !important;
    }
    }
    tbody::-webkit-scrollbar{
      display: none;
    }
  }
  .classes-full-calendar{

    .ant-picker-cell-selected{
      .date-fullcell-responsive{
      background-color: #1d615a;
      width: 36px;
      border-radius: 50%;
      margin: auto;
      color: #fff;
      .calendar-circle{
        display: none !important;
      }
      }
  
    }

    .ant-picker-body{
      text-align: center;
    }
    .ant-picker-cell{
      padding: 8px 0px 0px 0px !important;
      
    .ant-picker-calendar-date{
          height: 35px !important;
          min-width: 35px !important;
          width: 35px !important;
          border: transparent !important;
          display: flex !important;
          border-radius: 25px !important;
          justify-content: center;
          padding: 7px 8px 0 !important;
          background-color: #fff;
          font-family: Gilroy;
          font-size: 14px ;
          font-weight: 600;
    }

    .date-fullcell-responsive{
      min-height: 36px !important;
      // min-width: 45px !important;
      // width: 45px !important;
      //flex-direction: column;
      // border: transparent !important;
      // display: flex !important;
      //border-radius: 25px !important;
      // justify-content: center;
      padding: 7px 8px 0 !important;
      // background-color: #fff;
      font-family: Gilroy;
      font-size: 14px ;
      font-weight: 600;    }
    }
    thead{
      //display: none;
      opacity: 0.5;
      font-family: Gilroy;
      font-size: 14px;
      font-weight: 600;
      color: #262626;
      tr{
        border-top: 1px solid #e8efee;
        border-bottom: 1px solid #e8efee;
        //khan
      }
    }
    tbody{
      display: contents;
    }
  }
  .bottom-navigation{
    display: flex !important;
    padding: 24px 48px 24px 48px !important;
  }
  .sider{
    display: none !important;
  }
  .menuIconDiv{
    display: none !important;
  }
  .logo-link{
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .logo-header{
    display: flex !important;
    padding: 0 !important;
    width: 165px !important;
  }
  .logo-image-header{
    height: 36px;
  }
  .ant-layout.site-layout-background{
    //margin: 0px;
    //padding: 0px 56px 100px 56px !important;
  }
  .main-page-container{
    padding: 0px 56px 90px 56px !important;
    overflow: scroll;
  }
  .userName{
    display: flex !important;
    //margin-right: 5px !important;
  }
  .PlusDiv{
    display: block !important;
  }
  .content-profile{
    .ant-popover-content{
      margin-right: 55px !important;
    }
  }
  .views-graph{
    display: block !important;
  }
  .views-graph-label-container{
    margin-top: 30px;
  }
  .bottom-navigation-content-label{
    margin-top: -4px !important;
  }
  .earning-carousel{
    width: 98% !important;
  }

  .status-bar-main{
    width: 120px !important;
    // margin-right: 9px !important;
    font-size: 12px !important;
    padding: 3px 8px 0px 8px !important;
  }

  .simple-classes-card{
    margin-left: unset !important;
    &-content{
      //margin-left: 28px;  
    }
    &-content-title{
      font-size: 17px !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // display: inline-block;
    }
    &-content-description{
      font-size: 14px !important;
    }
    &-content-amount{
      font-size: 14px !important;
    }
    &-content-views{
      font-size: 14px !important;
    }
    &-content-amount {
      margin-right: 18px !important;
    }
    &-upper{
      width:31% !important;
      margin-left: 20px;
    }
  }

  .ant-picker-calendar-full .ant-picker-panel{
    padding: 0px 0px 16px 0px;
  }

  .earnings-weekly-graph{
    width: 14% !important;
  }

  .total-earnings-graph{
    &-left{
      width: 35% !important;
    }
    &-right{
      width: 65% !important;
      padding: 0px 70px 0px 70px !important;
    }
  }
  .nothing-screen{
    width: unset !important;
    margin: 20% 0% 2% 0% !important;
  }
  .edit-detail{
    // flex-direction: column;
    &-message{
      // width: 100% !important;
      // flex-direction: column;
      // padding: 0px 0px 50px 0px !important;
    }
    &-container{
      margin-bottom: 40px;
    }
    &-buttons{
      padding-top: 20px;
      display: flex;
      flex-direction: column;
    }
    &-button-box{
      padding-top: 20px;
    }
  }
  .edit-detail-support-button, button.edit-detail-support-button:hover,
    button.edit-detail-support-button:focus{
      width: 100% !important;
      height: 42px !important;
      border-color: #d2dfde;
      color: #262626;
      font-weight: 600 !important;
  }

  .test-home-screen{
    overflow-y: scroll;
    &-container{
      width: 70% !important;
      margin: auto;
    }
    &-title{
      margin-top: 5%;
    }
  }
  .waiting-screen{
    &-bottom{
      &-left{
        width: 40% !important;
      }
      &-middle{
        width: 25% !important;
      }
      &-right{
        width: 35% !important;
      }
    }
  }
  .middle-icon{
    margin-left: 14px !important;
    margin-right: 14px !important;
  }
  .test-audio-screen{
    &-box{
      height: 250px !important;
    }
    &-container{
      width:80% !important;
    }
  }
  .direct-coach-student-values{
    .square-small{
      display: none;
    }
  }
  .create-direct-coach{
    flex-direction: column;
    height: calc(100vh - 86px) !important;
    overflow-y: auto;
    &-left{
      width: 100% !important;
      background-color: #fff !important;
      &-logo{
        display: none !important;
      }
      &-heading{
        width: 100% !important;
        margin-top: 24px !important;
        text-align: center;
      }
    }
    &-right{
      width: 100% !important;
      height: calc(100% - 190px);
      &-bottom{
        width: 100% !important;
      }
    }
  }
  .direct-coach-heading-component{
    color: black !important;
  }
  .dc-class-home{
    width: 450px !important;
    &-input{
      width: 450px !important;
      margin-top: 24px;
    }
    &-card{
      &-container{
        padding-bottom: 60px !important;
      }
    }
  }
  .direct-coach-cost{
    width: 70%;
  }
}


.ots-date-text {
  color: rgb(174, 180, 190);
  margin: auto;
  font-size: 1.2vw;
}

.ots-name-select {
  // font-size: 1.2vw !important;
  .ant-select-selector {
    // height: 6.5vh !important;
    border: 1px solid rgb(153 153 153 / 37%) !important;
    width: 100% !important;
    text-align: start;
  }
  .ant-select-selection-placeholder {
    margin: auto;
    text-align: start;
    color: #383838;
  }
  .ant-select-selection-search {
    // margin-top: 4px;
    display: flex !important;
  }
  .ant-select-selection-item {
    margin: auto;
  }
  .ant-select-selection-search-input {
    // margin-top: 4% !important;
    text-align: start;
    margin: auto !important;
  }
}

.ots-currency-select {
  .ant-select-selector {
    // height: 6.5vh !important;
    border: 1px solid rgb(153 153 153 / 37%) !important;
    //background-color: rgb(213, 213, 213) !important;
    width: 100% !important;
    text-align: start;

    .ant-select-selection-placeholder {
      // margin-top: 5px;
      margin: auto;
      color: #383838;
    }
  }
  .ant-select-selection-search-input {
    margin-top: 5px !important;
    text-align: start;
    // margin: auto;
  }
  .ant-select-selection-item {
    margin: auto;
  }
  .ant-select-selection-search {
    // margin-top: 4px;
    margin: auto;
  }
}

.ots-student-input {
  // font-size: 1.2vw !important;
  .ant-input-number-input {
    text-align: start;
    //margin-top: 2px;
  }
}

.legend {
  margin: auto !important ;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  overflow: auto !important;
  // margin: auto !important;
  // top: 50px !important;
  font-weight: 800 !important;
  font-size: 3vw !important;
  // left: 550px !important;
  // margin-left: 0px !important;
  color: black !important;
  opacity: 1 !important;
  // padding: 0px !important;
  background: none !important;
  // width: auto !important;
  text-align: center !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.legend-heading-one {
  // padding-bottom: 0 !important;
  // margin-bottom: 0px !important;
  //   margin-top: 25px !important;
}
.legend-heading-two {
  font-weight: 300 !important;
  font-size: 3vw !important;
  // font-family: "Gilroy Light",;
}

@media (max-width: 767px) {
  .legend-heading-one {
    font-weight: 800 !important;
    font-size: 21px !important;
    // margin-top: 15px !important;
  }
  .legend-heading-two {
    font-size: 18px !important;
  }
}

@media (max-width: 425px) {
  .legend-heading-one {
    font-weight: 800 !important;
    font-size: 15px !important;
    // margin-top: 0px !important;
  }
  .legend-heading-two {
    font-size: 12px !important;
  }
}

.ots-datetimepicker {
  // font-size: 1.2vw !important;
  #basic_classDate {
    margin: auto;
    text-align: start;
  }
  #basic_startTime {
    // font-size: 1.2vw !important;
    margin: auto;
    text-align: start;
    color: #383838;
  }
  #basic_endTIme {
    margin: auto;
    text-align: start;
  }
}

.ots-home-image {
  margin-top: 10 !important;
  max-width: 100% !important;
  width: 285 !important;
  max-height: 172 !important;
  height: auto !important;
  overflow: "hidden" !important;
  position: "relative" !important;
}

.input-wrapper {
  .login-input {
    background-color: #d5d5d5;
    border-radius: 0px;
    color: #383838;
    padding: 10px 20px;
    height: 55px;
    border-width: 0px !important;
    width: 100%;
    font-size: 18px;
    font-family: "Gilroy", sans-serif !important;
    font-weight: 500;
    text-align: left;
  }
  .login-input:hover {
    border-width: 0px;
  }
  .login-input::placeholder {
    color: #383838;
  }
}
.login-password{
  > input{
    background-color: #d5d5d5 !important;
    text-align: start;
  }
}

input::internal-autofill-selected {
  background-color: #feb264 !important;
}
.ots-carousel {
  .selected {
    background: #383838 !important;
  }
  .dot {
    background: #7b7b7c !important;
  }
}

.loading {
  svg {
    fill: #8fb594;
  }
}

#root {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}
input::placeholder {
  color: #383838 !important;
}
.ots-home {
  .ant-select {
    .ant-select-selector{
      height: 40px !important;
    }
  }
  .ant-input, .ant-picker  {
    height: 40px !important;
  }
  .ant-input-number {
    height: 40px !important;
  }
}
.create-class-info {
  min-height: 420;
}

.form-input-item {
  .ant-row {
    .ant-col-18 {
      max-width: 'calc(100% - 50px)';
    }
  }
}

@media (max-width: 767px) {
  .create-class-info {
    min-height: auto;
  }
  .form-input-item {
    .ant-row {
      .ant-col-18 {
        max-width: 100%;
      }
    }
  }
}
.ant-btn {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1500px) {
  .ant-select-selection-placeholder {
    line-height: 2vw !important;
  }
  .ots-home {
    .ant-select {
      .ant-select-selector{
        height: 3vw !important;
      }
    }
    .ant-input, .ant-picker  {
      height: 3vw !important;
    }
    .ant-input-number, .ant-input-number-input, .time-zone .css-yk16xz-control {
      height: 3vw !important;
      margin-top: unset !important;
    }
  }
  .ots-name-select {
    font-size: 30 !important;
    line-height: 30 !important
  }
  .ots-datetimepicker {
    font-size: 1.2vw !important;
    #basic_startTime {
      font-size: 1.2vw !important;
    }
    #basic_classDate {
      font-size: 1.2vw !important;
    }
    #basic_endTIme {
      font-size: 1.2vw !important;
    }
  }
  .ots-currency-select {
    font-size: 1.2vw;
  }
  .ots-student-input, .ant-input-number-input, .ant-select-selection-placeholder {
    font-size: 1.2vw;
  }
  .activate-ots-button {
    font-size: 1.2vw;
  }
}

.testscreen-heading{
  font-weight: bold;
    display: flex;
    justify-content: center;
    font-size: 40px;
    padding: 4% 0%;
}
.testing-flow{
  display: flex;
  flex-direction: row;
}
.test-mainscreen{
  padding: 0% 20%;
}
.divider{
  width: 100%;
  align-self: center;
}
.audio-block{
  align-self: center;
}
.video-icon{
  align-self: center;
}
.divider-line{
  margin: 0px 0px 40px 0px !important;
}
.title-testicons{
  margin-top: 5px;
  text-align: center;
  font-family: "Gilroy", sans-serif !important;
  font-weight: 500;
  // color: #383838 !important;
}

@media (min-width: 768px) {
  .classes-calendar{
    .ant-picker-body{
      text-align: center;
    }
    // ant-picker-cell-inner ant-picker-calendar-date
    .ant-picker-cell{
      //padding: 8px 0px 0px 0px!important;
      .ant-picker-calendar-date{
        border-top: unset !important;
        border: solid 1px #e8efee !important;
        background-color: #fff;
        font-family: Gilroy;
        font-size: 14px ;
        font-weight: 600;
        text-align: center;
        //padding: 0 !important;
      } 
    }
    thead{
      th{  
      font-family: Gilroy;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 18px;
      color: #262626;
      }
    }
  }
}

.calendar-timeline{
  width: 30%;
  .calendar-scroll-classes {
    padding: 3px 20px 0px 3px;
    max-height: 770px;
  }
}
.calendar-view{
  width: 70%;
}
.calendar-header{
  padding: 8px;
  border: 1px solid #e8efee ;
  &-responsive-arrow{
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .calendar-today-button{
    margin-left: auto !important;
  }
  .calendar-timeline{
    padding-top: 15px;
    width: 100%;
    .calendar-scroll-classes {
      padding: 0px;
      max-height: 477px;
      //max-height: calc(100vh - 325px) !important;
    }
  }
  .calendar{
    flex-direction: column-reverse;
  }
  .calendar-header{
    padding: 14px 8px;
    border: none;

    // &-arrows{
    //   display: none;
    // }
    &-down-arrow{
      align-self: center;
      margin-left: 10px;
    }
    &-responsive-arrow{
      display: flex;
    }
  }
  .calendar-month-title{
    //width: auto !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    margin-left: 0px !important;
  }
  .calendar-view{
    width: 100%;
  }
  .classes-calendar{  
    .ant-picker-body{
      text-align: center;
    }
    .ant-picker-cell{
      padding: 8px 0px 0px 0px!important;
    .ant-picker-calendar-date{
          height: 35px !important;
          min-width: 35px !important;
          width: 35px !important;
          border: transparent !important;
          display: flex !important;
          border-radius: 25px !important;
          justify-content: center;
          padding: 7px 8px 0 !important;
          background-color: #fff;
          font-family: Gilroy;
          font-size: 14px ;
          font-weight: 600;
    }
    .date-fullcell-responsive{
      height: 35px !important;
      min-width: 35px !important;
      width: 35px !important;
      flex-direction: column;
      // border: transparent !important;
      display: flex !important;
      border-radius: 25px !important;
      // justify-content: center;
      padding: 7px 8px 0 !important;
      // background-color: #fff;
      font-family: Gilroy;
      font-size: 14px ;
      font-weight: 600;    }
    }
    thead{
      display: none;
    }
    tbody{
    display: flex;
    overflow: scroll;
    height: 100%;
    width: calc(100vw - 30px);
    }
    tbody::-webkit-scrollbar{
      display: none;
    }
  }
  .classes-full-calendar{
    .ant-picker-body{
      text-align: center;
    }
    .ant-picker-cell{
      padding: 8px 0px 0px 0px!important;
    .ant-picker-calendar-date{
          height: 35px !important;
          min-width: 35px !important;
          width: 35px !important;
          border: transparent !important;
          display: flex !important;
          border-radius: 25px !important;
          justify-content: center;
          padding: 7px 8px 0 !important;
          background-color: #fff;
          font-family: Gilroy;
          font-size: 14px ;
          font-weight: 600;
    }
    .date-fullcell-responsive{
      height: 35px !important;
      min-width: 35px !important;
      //width: 35px !important;
      flex-direction: column;
      // border: transparent !important;
      display: flex !important;
      border-radius: 25px !important;
      // justify-content: center;
      padding: 7px 8px 0 !important;
      // background-color: #fff;
      font-family: Gilroy;
      font-size: 14px ;
      font-weight: 600;    }
    }
    thead{
      display: none;
    }
    tbody{
      display: contents;
    }
  }
  .divider-line{
    margin: 0px 0px 30px 0px !important;
  }
  .volume-heading{
      font-size: 16px !important;
  }
  .test-buttons{
    padding: 12% 5% !important;
  }
  .network-img{
    width: 70px !important;
  }
  .test-webcam{
    width: 325px !important;
    height: 183px !important;
  }
  .test-network-section{
    padding: 26% 0% 16.6% 0% !important;
  }
  .baar{
    background-color: crimson;
  }
  .statement-card{
    display: flex;
    justify-content: space-between;
    &-dateamount{
      display: flex;
      flex-direction: column;
    }
    &-date{
      width: 100% !important;
    }
    &-pdfcontainer{
      margin-left: 10px;
    }

  }
  .modal{
    &-heading{
      padding: 30px 30px !important;
    }
    &-button{
      width: 136px !important;
    }
    &-button-cancel{
      width: 136px !important;
    }
  }
  .edit-ots-main{
    flex-direction: column;
    padding-right: 0 !important;
  }
  .edit-ots{
    flex-direction: row !important;
    &-icon{
      width: 5%;
      display: flex;
      margin-right: 10px;
      align-self: center;
      // justify-content: center;
    }
    &-icon-heading{
      width: 50%;
      display: flex;
      align-self: center;
      margin-top: 5px;
    }
    &-icon-value{
      width: 50%;
      margin-top: 3px;
      font-size: 18px !important;
      // text-align: end;
      display: flex;
      align-self: center;
      flex-direction: row-reverse;
    }
  }
  .edit-card-main{
    height: 233px !important;
  }
  .edit-card-cover-overlay{
    width: 100%; 
    height: 233px !important;
  }
  .edit-card-back-arrow{
    margin: 17px 0px 0px 17px !important;
  }
  .coaching-card-image-title{
    &-heading{
      font-size: 24px !important;
    }
    &-description{
      font-size: 18px !important;
    }
  }
  .edit-detail{
    flex-direction: column;
    &-container{
      width: 100% !important;
    }
    &-message{
      width: 100% !important;
      padding: 0px 0px 50px 0px !important;
    }
    &-buttons{
      justify-content: center !important;
      flex-direction: column;
      padding-top: 0 !important;
    }
    &-button-box{
      padding-top: 20px;
    }
    &-timeline{
      padding-right: 0px !important;
    }
  }
  .edit-detail-support-button, button.edit-detail-support-button:hover,
  button.edit-detail-support-button:focus{
   width: 100% !important;
   height: 42px !important;
   border-color: #d2dfde;
   color: #262626;
   font-weight: 600 !important;
  }
}
.test-icon-img{
  width: 87px;
  height: auto;
}
.volume-slider{
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-family: "Gilroy Medium", sans-serif !important;
  color: #9ca3af;
}
.slider{
  .ant-slider-rail{
    height: 8px;
    border-radius: 5px;
  }
  .ant-slider-handle{
    height: 18px;
    width: 18px;
    background-color: #8fb594;
    border: none;
  }
  .ant-slider-step{
    height: 8px;
    background-color: #8fb594;
    border-radius: 5px;
    opacity: 0.5;
  }
  // .ant-slider-track:hover{
  //   background-color: black;
  // }
  // &:hover {
  //     background: #222;
  //   }
  .ant-slider-track{
    height: 8px;
    border-radius: 5px;
    background-color: #8fb594;
  }
}
.test-audio-section{
  padding: 2% 0% 4.8% 0%;
}
.test-network-section{
  padding: 12% 0% 9.6% 0%;
}
.test-webcam-section{
  padding-top: 4.1%;
}
.volume-heading{
  font-weight: bold;
    font-size: 20px;
    align-self: center;
}
.volume-subheading{
  font-family: "Gilroy Medium", sans-serif !important;
  margin-left: 5px;
  font-size: 12px;
}
.test-buttons{
  padding: 3% 5%;
}
.testscreen-button{
  font-family: "Gilroy", sans-serif !important;
  text-shadow: none !important;
  font-weight: 600 !important;
  font-size: 10 !important;
   > span{
   position: absolute; 
  }
}
.testscreen-checkbox{
  // color: #383838;
  .ant-checkbox .ant-checkbox-inner {
    color: #383838;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #8fb594;
    border-color: #8fb594;
  }

  .ant-checkbox-wrapper :hover{
    border-color: #d5d5d5 !important;
  }

}
.network-icon{
  justify-content: center;
  width: 100%;
  display: flex;
}
.network-img{
  width: 107px;
}
.test-webcam{
  width: 433px;
  height: 244px;
  box-shadow: none;
  border-radius: 2px;
  transform: rotateY(180deg);
}
.webcam-main{
  justify-content: center;
  width: 100%;
  display: flex;
}
.student-detail{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px !important;
  font-weight: 500;
}
.student{
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 3%;
  margin-bottom: 13%;
}
.student-detail-description{
  color: #aeb4be;
}

// .speaker-canvas{
//   width: 600 !important;
//   height: 300 !important;
//   margin: auto !important;
//   display: flex !important;
// }
.sound-wave{
  width: 80px !important;
  height: 40px !important;
  margin-top: -17px;
  // display: flex !important;
}

@media (min-width: 767px) and (max-width: 867px) {
  .time-zone {
    .css-yk16xz-control {
      height: 3.5vh;
    }
  }
}
.ots-message{
  font-size: 15px;
  font-weight: 600;
  color:#8fb594;
  text-decoration:underline;
  text-decoration-color: #8fb594;
  margin-left: 5px;
}

// .menuItem{
//   display: flex !important;
//   flex-wrap: wrap;

// }
.ant-menu-item menuItem{
  height: 60px;
}
.sideNavli{
  font-size: 16px;
  color: #262626;
  font-weight: 600;
  padding-top: 0px;
  margin-left: 10%;
}


.ant-menu-inline .ant-menu-item-selected::after{
  //border-right: 3px solid $themeColor !important;
  display: none;
}

.avatar{
  //vertical-align: middle;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: 5%;
}


.ant-layout-header{
  padding: 0px 0px !important;
}

.anticon-bars svg{
  //display: none !important;
  color:white;
  width: 20px;
  height: 20px; 
}

.PlusDiv{
  width:56px;
  height: 80%;
  background-color:$themeColor;
  float:right;
  margin-right: 8px;
  margin-left: auto;
  text-align:center;
  border-radius: 2px;
  polygon{
    fill: #fff !important;
  }
}

.plus{
  height: 20px;
  margin-bottom: 5%;
}

.anticon.anticon-bell{
margin-right: 3%;
}
.ant-menu-item.ant-menu-item-only-child{
  padding-left: 0 !important;
}

.notification-icon{
  margin-left: 10px;
}

.graph-label-reactangle{
  height: 16px;
  width: 24px;
  border-radius: 10px;
  float: right;
}

.graph-label{
  margin: 0px 22px 0px 15px;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 600;
  color: #262626;
}


 
.sideNavBar {
  background-color: transparent !important;
  width: auto !important;
  //margin-top: -22px !important;
}

.menuIconDiv{
  justify-content: center;
  padding: 5px;
  margin-left: 1%;
}
.menuIcon{
  line {
    stroke: $themeColor;
  }
  cursor: pointer;
  margin-left: 2px;
  margin-top: 2px;
}

.close-icon{
  //margin-right: 10px;
  margin-bottom: 8px;
  margin-top: 5px;
  margin-left: 7px;
}


.menu-item  {
  display: flex !important;
  align-items: center;
  //display: flex;
  padding: 20px !important;
  &.collapsed {
    justify-content: space-around;
    padding: 20px !important;
   
  }
}

.logo-image-small{
  max-width: 40px;
}

.ant-popover-inner-content{
padding: 0 !important;
border: none !important;
background: none !important;
}

.plus-drawer{
  border: none !important;
  background: none !important;
  background-color: transparent !important;
  display: flex;
  flex-direction: column;
  padding: 0px 10px !important;
  align-items: flex-end;
}

.transparent-popup {
  .ant-popover-content {
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner {
      box-shadow: none;
    }
  }
}


.page-inner-title {
  font-family: Gilroy;
  font-size: 32px;
  font-weight: bold;
  color: #262626;
}

.flex-wrapper {
  display: flex;
  flex-wrap: wrap;
  &-item-50 {
    flex: 50% 0;
    border-radius: 10px;
    background-color: #ffffff;
  }
}

.perfomance-item {
  display:flex;
  justify-content:space-between;
  align-items: center;
}

.perfomance-item-title{
  font-size: 16px;
  font-weight: 600;
  font-family: Gilroy;
  color:#262626;
  display: flex;
  align-items: center;
}

.perfomance-item-count{
  font-size: 24px;
  font-weight: 700;
  font-family: Gilroy;
  color:#262626;
}

.uparrow-icon{
  margin-right: 8px;
}
.uparrow-icon path{
  fill: $themeColor;
}

.down-arrow-icon{
  margin-right: 8px;
}
.down-arrow-icon path{
  fill: $themeColor;
}

.logosmall{
  padding: 14px;
}


.your-top-three{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 45px;
  &-title{
    font-weight: bold;
    font-size: 24px;
    display: flex;
    align-items: center;
    font-family: Gilroy;
  }
  &-see-all{
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 10px;
  }
}

// .fav-icon path{
//   fill: red
// }

.card-cover{
  position: relative;
  width: 100%;
  &-container{
    position:absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:20px;
  }
  &-count{
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    font-family: Gilroy;
    margin-left: 10px;
  }
  &-image-title{
    position:absolute;
    top:50;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
  }
  &-image{
    object-fit:cover;
    width: 100%;
    border-radius: 10px 10px 0 0 !important;
    // &-image{
      // position: absolute;
      // content:"";
      // height:100%;
      // width:100%;
      // top:0;
      // left:0;
      // background: linear-gradient(to bottom, rgba(255,0,0,0) 0%,rgba(255,0,0,0.65) 100%);
    // }
  }
  &-detail{
    .ant-card-meta-title{
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 1;
    }
    .ant-card-meta-description{
      font-size: 16px;
      font-weight: 400;
      color: #262626;
    }
  }
  &-overlay{
    background-color: #00000082;
    position: absolute;
    opacity: 0.7;
    width: 100%;
    height: 175px;
  }
}

.card-cover-audience{
  position: relative;
  width: 100%;
  &-container{
    position:absolute;
    left: 0;
    right: 0;
    bottom: 0;
    // display: block;
    justify-content: center;
    align-items: center;
    padding:20px;
    font-size: 24px;
    font-weight: 600;
  }
  &-count{
    // font-size: 24px !important;
    font-weight: bold;
    color: #ffffff;
    font-family: Gilroy;
    margin-left: 10px;
  }
  &-image-title{
    position:absolute;
    top:22px;
    left: 30px;
    display: flex;
    font-size: 16px !important;
    font-weight: 600;
    color: white;
    font-family: Gilroy;
    z-index: 9;
  }
  .___wrappper {
    &::after{
      position: absolute;
      content:"";
      height:100%;
      width:100%;
      top:0;
      left:0;
      opacity: 0.5;
      border-radius: 10px;
      background-blend-mode: color, normal;
      background-image: linear-gradient(to bottom, #d8d8d8, #d8d8d8), linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
    }
  }
  ._imagegray{
    filter: grayscale(1);
  }
  &-image{
    object-fit:cover;
    width: 100%;

  }
}

.dollar-icon path, .visibility-icon path{
  fill: white;
}
.ant-layout.site-layout{
  background-color: white;
}

.ant-layout.site-layout-background{
  margin: 0px;
  // padding: 0px 72px 10px 72px;
  max-height: 100%;
  overflow-y: auto;

}
.main-page-container{
  padding: 0px 72px 10px 72px;
  overflow: scroll;
}
.main-coaching-container{
  padding: 0px 72px 10px 72px;
  overflow: scroll;
}
.custom-cluster {
  background-color: $themeColor  !important;
  &::before {
    background: transparent !important;
    border: solid 2px $themeColor !important;
  }
}
.custom-pointer {
  background-color: $themeColor  !important;
}

.location-map{
  .mapboxgl-ctrl-logo{
    display: none;
  }
  .mapboxgl-ctrl-attrib{
    display: none;
  }
}

.audience-card{
  .ant-card-body{
    display: none;
  }
  box-shadow:0px 0px 10px 1px #9999995e;
}

.ant-menu-item.menu-item.ant-menu-item-selected{
  background-color: transparent !important;
}

.add-new-class-button{
  
  border: solid 1px #e8efee !important;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 5%) !important;
  background-color: #ffffff !important;
  width: 150px;
  border-radius: 2px !important;
  height: 48px !important;
  &-span{
    font-family: Gilroy !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #262626 !important;
  }
}

.direct-coach-button{
  margin-top:3%;
  width: 200px !important;
  height: 48px !important;
  border-radius: 2px !important;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05) !important;
  border: solid 1px #e8efee !important;
  background-color: #fff !important;
  font-size: 16px;
  font-weight: 600;
  color: #262626;
}

.card-cover-main{
  border-radius: 10px !important;
  box-shadow:0px 0px 10px 1px #9999995e;
}

.logo-small{
  display: flex;
  justify-content: center;
  padding: 12px;
  height: 75px;
}

.header-new {
  // padding: 0 4% !important;
  background-color: #383838 !important;
  //padding-left: 10px !important ;
  //padding-right: 0px; 
}

.logo-new {
  float: left;
  width: 160px;
  // height: 31px;
  // margin: 16px 24px 16px 0;
  // background: rgba(255, 255, 255, 0.3);
}

.logo-image-new {
  width: 100%;
  margin-left: 10%;
  // margin-bottom: 14px;
}

.dollar-icon{
  height: 22px;
  width: 12px;
  //margin-bottom: 5px;
}

.audience-section{
  display: flex;
  flex-wrap: wrap;
}

.audience-map{
  width: 50%;
}

.views-graph{
  display: flex;
  justify-content: space-between;
  &-buttons{
    display: flex;
  }
  &-label-container{
    display: flex;
    align-self: center;
  }
}

.audience-collapse{
  .ant-collapse-header{
    padding: 0px !important;
    cursor: pointer !important;
  }
}

.audience-panel{
  .ant-collapse-content-box{
    padding: 10px !important;
  }
}

.analytic-banner-collapse{
  .ant-collapse-header{
    cursor: pointer !important;
  }
}

.progress {
  background-color: #f5f5f5;
  border-radius: 3px;
  box-shadow: none;
}

.progress.vertical {
  position: relative;
  width: 24px;
  height: 230px;
  display: inline-block;
  // margin-right: 10px;
  // transform: rotate(90deg);
}

.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: transparent;
  border-radius: 20px;
  // -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  // box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress.vertical > .progress-bar {
  width: 100% !important;
  position: absolute;
  bottom: 0;
}

.progress-bar {
  border-radius: 20px;
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: transparent;
  text-align: center;
  background-color: #1d615a;
  // -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  // box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.vertical-graph-label{
  text-align: center;
  font-size: 16px !important;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: bold;
  color: #262626;
}
.logo-header{
  display: none;
}

.sider{
  overflow: hidden;
  background-color: white !important;
  box-shadow: inset -12px -12px 10px -10px #d5d5d5;
}

.bottom-navigation{
  background-color: white;
  height:56px;
  width: 100%;
  position: fixed !important;
  bottom: 0 !important;
  display: none;
  justify-content: space-between;
  align-items: center;
  padding:16px;
  border-top: 2px solid#e8efee;
  z-index: 999;
  &-content{
    text-align: center;
    padding-top: 7px;
  }
  &-content-label{
    font-family: Gilroy;
    font-size: 12px;
    font-weight: 600;
    color: #262626; 
    
  }
  &-menu{
    width: 100% !important;
    .ant-popover-content {
      .ant-popover-arrow {
        display: none;
      }
      .ant-popover-inner{
        border-radius: 20px 20px 0px 0px;
        //box-shadow:none !important;
        box-shadow: 0px -4px 14px 0px #c0c1c138;
      }
    }  

  }
  &-menu-content{
    display: flex;  
    align-items: center;
    padding: 10px ;
    margin-left: 10px;
    }
  &-menu-content-label{
    margin-left: 20px;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 600;
    color: #262626;
  }
}

.plus-div-bottom{
  background-color: $themeColor;
  width: 40px; 
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  &-plus{
    margin: auto;
  }
    polygon{
      fill: #fff !important;
    }
}

.content-profile{
  .ant-popover-content{
    margin-right: 75px;
    .ant-popover-arrow{
      display: none;
    }

  }
  &-data{
    display: flex;
    align-items: center;  
  }
}


.profile-drawer{
  width: 152px;
  border:none !important;
  &-button{
    background-color: transparent !important;
    padding:10px;
    width: 100%;
    height: 48px !important;
    border:4px solid white !important;
  }
  &-button-span{
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 600;
    color: #262626;
  }
  &-button-logout{
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 600;
    color: #262626;
    margin-bottom: 6px;
  }
}

.userName{
  font-family: Gilroy;
  font-size: 16px;
  text-align: right;
  color: #262626;
  margin-left: 10px;
  margin-right: 15px !important;
  cursor: pointer;
}

.add-new-class-button-bottom{
  font-family: Gilroy;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #262626 !important;
  width: 152px;
  height: 48px !important;
}

.add-new-coaching-class-button-bottom{
  font-family: Gilroy;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #262626 !important;
  width: 200 !important;
  height: 48px !important;
}

.your-top-three-main{
  display: flex;
}

.your-top-three-card{
  width: 50%;
}
.location-values{
  font-size: 16px;
  font-weight: 500;
}

.menu-span{
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 600;
  color: #262626;
}
.popupover-cross-icon {
  fill: #1d615a;
}

.ant-collapse-item-active {
  .perfomance-item-graph {
    visibility: hidden;
  }
}

.bottom-navigation-menu1{
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
}

.body-overlay {
  width: 100vw;
  height: calc(100vh - 56px);
  position: fixed;
  background-color: #929292a1;
  left: 0;
  top: 0;
  z-index: 999;
}

.no-scroll {
  overflow: hidden !important;
  .bottom-navigation {
    &::before {
      content: '';
      background:#929292a1;
      position: absolute;
      width: 100vw;
      height: 100vh;
      bottom: 55px;
      left: 0;
    }
  }
}


.bottom-navigation-menu-popover {
  left: 0 !important;
  width: 100vw;
  padding: 0px 0px 2px 0px !important;
  .ant-popover-content {
    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      background-color: white !important;
        border-radius: 20px 20px 0px 0px;
        box-shadow: 0px -4px 14px 0px #c0c1c138;
    }

    
  }
}

.bottom-navigation-menu-popover2 {
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  background-color: #ffc0cb30;
  position: fixed !important;
  .ant-popover-content {
    display: flex;
    height: inherit;
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner{
      width: 100%;
      height: auto;
      background-color: inherit;
      &-content {
        position: absolute;
        bottom: 65px;
        width: 100%;
        background-color: white !important;
        border-radius: 20px 20px 0px 0px;
        box-shadow: 0px -4px 14px 0px #c0c1c138;
      }
    }
  }
}

.classes-button {
  margin-left: 32px;
  width: 120px;
  height: 48px !important;
  border-radius: 2px !important;
  background-color: #1d615a !important;
  // display: none !important;
  //padding: 0px 5px 0px 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &-span{
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    margin:auto;
  }
}

.classes-tab{
  overflow: unset !important;
  .ant-tabs-tab-btn{
    width: 45px;
    margin-left: 16px;
    height: 17px;
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 600;
    color: #262626;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 600;
    color: #262626;
  }
  .ant-tabs-tab-disabled{
    .ant-tabs-tab-btn{
    color: #A8A8A8 !important;}
  }
  .ant-tabs-ink-bar {
    background-color:#1d615a !important;
    width: 100px !important;
    height: 4px !important;
  }
  &-heading{
    font-family: Gilroy;
    font-size: 24px;
    font-weight: bold;
    color: #262626;
  }
  &-description{

    font-family: Gilroy;
    font-size: 16px;
    color: #262626;
  }
  &-calendar{
    font-family: Gilroy;
    font-size: 16px;
    color: #262626;
    margin-top: 16px;
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    font-weight: 600 ;
    cursor: pointer;
  }
}


.simple-classes-card{
  display: flex;
   align-items: center;
   //box-shadow: 0 0 10px 1px #9999995e;
   width: 80%;
   margin-left: 20px;
   &-content{
    display: flex;
    flex-direction:column;
    width: 100%;
    //margin:15px 0px 20px 24px;
   } 
   &-content-title{
    font-family: Gilroy;
    font-size: 20px;
    font-weight: 600;
    color: #262626;
   }
   &-content-description{
    font-family: Gilroy;
    font-size: 16px ;
    color: #262626 ;
    opacity: 0.5;
   }
   &-content-views{
     display: flex;
     //width: 50px;
     align-items: center;
     margin-left: auto;
     font-family: Gilroy;
     font-size: 16px;
     font-weight: 600;
     color: #262626;
     width: 20%;
   
   }
   &-content-amount{
    display: flex;
    //width: 60px;
    align-items: center;
    margin-left: 30px;
    // margin-right: 30px;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 600;
    color: #262626;
    width: 20%;  
  
  }
  &-upper{
    display: flex;
    flex-direction: row;
    width:40%;
  }
  &-lower{
    width: 60%;
    margin-left: auto;
    margin-top: -16px;
  }
}

.simple-classes-card-row{
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05) !important;
  border-radius: 8px;
}

.simple-classes-calendar-card{
  display: flex;
  //  align-items: center;
   //box-shadow: 0 0 10px 1px #9999995e;
   width: 80%;
   margin-left: 10px;
   &-content{
    display: flex;
    flex-direction:column;
    width: 100%;
    //margin:15px 0px 20px 24px;
   } 
   &-content-title{
    font-family: Gilroy;
    font-size: 20px;
    font-weight: 600;
    color: #262626;
    display: inline-block;
    width: 135px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
   }
   &-content-description{
    font-family: Gilroy;
    font-size: 16px ;
    color: #262626 ;
    opacity: 0.5;
   }
   &-content-views{
     display: flex;
     //width: 50px;
     align-items: center;
     margin-left: auto;
     font-family: Gilroy;
     font-size: 16px;
     font-weight: 600;
     color: #262626;
     width: 20%;
   
   }
   &-content-amount{
    display: flex;
    //width: 60px;
    align-items: center;
    margin-left: 30px;
    // margin-right: 30px;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 600;
    color: #262626;
    width: 20%;  
  
  }
  &-upper{
    display: flex;
    flex-direction: row;
    width:40%;
  }
  &-lower{
    // width: 60%;
    // margin-left: auto;
    // margin-top: -16px;
  }
}

//sample
.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
  }  
//}

.earnings-main{
  //display: flex;
  // //align-items: center;
  // padding: 0px !important;
  margin-top: 71px;
}

.earning-carousel{
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
  //#80808040;
  width: 390px;
  margin-left: 6px;
  .control-next.control-arrow:before{
    border-left: 7px solid #525252 !important;
  } 
  
  .control-prev.control-arrow:before {
    border-right: 7px solid #525252 !important;
  }
}

.line{
  border-left: 1px solid #e8efee;
  height: 100vh;
}


.next-pay{
  height: 150px;
  background-color: #fff;
  &-content{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &-title{
    font-family: Gilroy;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #262626;
    margin-top: 16px;
  }
  &-amount{
    font-family: Gilroy;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #262626;
    margin-top: 16px;
  }
  &-date{
    font-family: Gilroy;
    font-size: 16px;
    text-align: center;
    color: #262626;
  }
}

.total-earnings{
  font-family: Gilroy;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #262626;
  &-amount{
    font-family: Gilroy;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #262626;
  }
}

.from-classes{
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 600;
  color: #262626;
  &-amount{
    font-family: Gilroy;
    font-size: 20px;
    font-weight: bold;
    color: #262626;
  }
}

.from-referrals{
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 600;
  color: #262626;
  &-amount{
    font-family: Gilroy;
    font-size: 20px;
    font-weight: bold;
    color: #262626;
  }
}

.earnings-tab{
  
  .ant-tabs-tab-btn{
    width: 75px;
    font-family: Gilroy;
    font-size: 14px !important;
    font-weight: 600 !important;
    text-align: center;
    color: #262626 !important;
    margin-right: 34px;
    margin-left: 20px;
    
  }
  .ant-tabs-ink-bar {
    background: #1d615a !important;
    width: 120px !important;
    height: 4px !important;
  }
  .ant-tabs-tab-disabled{
    .ant-tabs-tab-btn{

      color: #d3d3d3 !important;
    }
  }
  
  .progress{
    cursor: pointer;
  }
  .earning-graph-label{
    cursor: pointer;
  }
}

.earnings-total{
&-earning{
  display: flex;
  justify-content: space-between;
  margin-top: 64px;
}
&-classes{
  display: flex;
  justify-content: space-between;
  margin-top: 57px;
}
&-referrals{
  display: flex;
  justify-content: space-between;
  margin-top: 46px;
}
}

.total-earnings-graph{
  display: flex;
  margin-bottom: 72px;
  justify-content: center;
  &-left{
    width:30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-label{
    font-family: Gilroy;
    font-size: 18px;
    color: #262626;}
    &-amount{
      font-family: Gilroy;
      font-size: 32px;
      font-weight: 600;
      color: #262626;
      margin-top: 17px;
    }
  }
  &-right{
    width:70%;
    display: flex;
    flex-direction: column;
    padding: 0px 130px 0px 70px;
    &-classes{
      display: flex;
      justify-content: space-between;
      margin-bottom: 22px;
      &-label{
        font-family: Gilroy;
        font-size: 18px;
        color: #262626;
      }
      &-amount{
        font-family: Gilroy;
        font-size: 18px;
        font-weight: 600;
        color: #262626;
        //margin-right: 80px;
      }
    }
    &-referrals{
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      &-label{
        font-family: Gilroy;
        font-size: 18px;
        color: #262626;
      }
      &-amount{
        font-family: Gilroy;
        font-size: 18px;
        font-weight: 600;
        text-align: right;
        color: #262626;
        //margin-right: 80px;
      }
    }
  }
}

.earning-graph-buttons{
  display: flex;
justify-content: center;
margin-top: 54px;
}

.earning-graph-content{
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 54px;
  &-amount{
    font-family: Gilroy;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #262626;
  }
  &-date{
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #262626;
    margin-top: 25px;
  }
}

.earning-graph-label{
  font-family: Gilroy;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

// .earning-graph-arrow{
//   width:20px;
//   cursor: pointer;
//   margin-left: 25px;
//   background-color:red;
//   z-index: 999999;
// }


.earning-disabled-arrow{
  pointer-events: none;
  display: flex;
  width: 10px;
  position: absolute;
  top: 50;
  cursor: pointer;
  margin-top: 15px;

}

.earning-carousel{
  .earning-disabled-arrow{
    display: none
  }
}

.earnings-left, .earnings-right{
  width:50%
}

.earnings-total-main{
  padding: 0px 30px 0px 0px
}

div::-webkit-scrollbar{
    display: none;
    //width: 0px;
    //background: transparent; /* make scrollbar transparent */
}

.line-divider{
  border-top:1px solid #e8efee;
  width:100%;
  margin-bottom:71px;
  margin-top:47px;
}

.line-divider-statement{
  border-top:1px solid #e8efee;
  width:100%;
  margin-bottom:71px;
  margin-top:47px;
}

.earnings-graph{
  position: relative;
}

.earnings-weekly-graph{
  width: 9%;
}

.page-inner-title-main{
  display: flex;
  align-items: center;
  flex-direction: row;
  // margin-bottom: 24px;
  // margin-top: 10px;
  padding: 10px 0px 24px 0px;
}

.classes-card-image{
  width:20%;
  // min-width: 69px;
  img{
  width:100%;
  border-radius: 8px 0px 0px 8px;
  object-fit: cover;
  }
}

.classes-card-main{
  display: flex;
  position: relative;
}

.classes-tab-heading-main{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 16px;
}

.status-bar-main{
  width: 140px;
  height: 24px;
  border-radius: 16px;
  padding: 1px 8px 0px 8px;
  text-align: center;
  color: #fff;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  margin-right: 16px;
}

.earnings-loading{
  position: relative;
  height: calc(93vh - 230px);
}

.statements-main{
  margin-top: 49px;
}

.statement-card{
  font-size: 16px;
  font-weight: 400;
  &-date{
    text-align: left;
    float: left;
    width:33.33%;
    font-size: 16px;
    font-weight: 600;
  }
  &-amount{
    text-align: center;
    width:33.33%;
    float: left;
  }
  &-pdf{
    text-align: right;
    display: flex;
    justify-content: flex-end;
    width:33.33%;
    font-size: 14px;
    font-weight: 600;
  }
  &-pdf-text{
    margin-left: 10px;
  }
  &-pdf-icon{
    margin-right: 10px;
    margin-top: 3px;
  }
}
.classes-calendar{

  .ant-picker-body th{
    padding: 14px 0px 14px 0px !important;
    font-family: Gilroy;
    font-size: 12px;
    font-weight: 600;
    
  }

  .ant-picker-cell-selected{
    .date-fullscreen-cell{
      .date-fullscreen-cell-container{
        width: 24px;
        height: 24px;
        background-color: #1d615a;
        border-radius: 50%;
        margin: auto;
        margin-top: 5px;
        .date-fullscreen-cell-date{
          color: #fff;
         
        }
      }
    }
  }
    .date-fullscreen-cell{
      .date-fullscreen-cell-container{
          margin-top: 5px;
        .date-fullscreen-cell-date{
          font-family: Gilroy;
          font-size: 12px;
          font-weight: 600;
          text-align: center;
          //color: #262626;
        }
      }
    }

	.ant-picker-body{
    text-align: center;
	}
  // ant-picker-cell-inner ant-picker-calendar-date
  .ant-picker-cell{
    //padding: 0px!important;
    position: relative;
    // .ant-picker-calendar-date{
    //   margin: unset !important;
    //   border-top: unset !important;
    //   border-radius: 2px !important;
    //   border: solid 1px #e8efee !important;
    //   background-color: #fff;
    //   font-family: Gilroy;
    //   font-size: 14px ;
    //   font-weight: 600;
    //   text-align: center;
    //   //padding: 0 !important;
    // } 
    .ant-picker-calendar-date-content{
      display: table-cell;
      vertical-align: bottom;
      position: static;
    width: auto;
    height: 86px;
    overflow-y: auto;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5715;
    text-align: left;
    }
  }
  thead{
    border: 1px solid #e8efee;

    th{  
    text-align: center;
    color: #a6a6a6;
  }
  
  }
  .ant-picker-calendar .ant-picker-panel .ant-picker-body{
    padding: 0px !important;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    padding: 0px !important;
  }

  
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date:hover{
    background-color: #fff !important;
    .ant-picker-calendar-date-value{
      color:black;
    }
  }
}


//starts calendar badge

.events{
  position: absolute;
  // display: inline-block;
  // align-self: flex-end;
  bottom: 0;
  width: 100%;
  
}

.events li{
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  width: 99%;
  margin-bottom: 2px;
  bottom: 0;
  left: 0;
  display: flex;
}



.calendar-badge-main{
  &-image{
    position:relative; 
  } img{
    object-fit: cover;
  }
  &-statusbar{
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 10px;
  }
  &-content{
    display: flex;
    flex-direction: column;
    padding: 16px;
    &-title{
      font-family: Gilroy;
      font-size: 16px;
      font-weight: 600;
      color: #262626;
    }
    &-description{
      font-family: Gilroy;
      font-size: 14px;
      font-weight: 600;
      color: #262626;
      opacity: 0.5;
    }
    &-clock{
      display: flex;
      &-span{
        margin-left: 10px;
        font-family: Gilroy;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: #262626;
      }
    }
}
}

.calendar-badge{
  width: 100%;
  //display: flex;
  padding: 1px 5px 1px 5px;
  height: 20px;
  &-popup-span{
    width:100%;
    position: inherit;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis;
    display: inline-block;
    align-self: flex-end;
    text-align: left;
  }
}

.calendar-scroll-classes{
  &-card{
    //box-shadow: 10px 10px 5px 5px;
    border-radius: 8px;
    box-shadow: 0 0 5px 2px rgb(0 0 0 / 5%) !important;
    &-header{
      font-family: Gilroy;
      font-size: 24px;
      font-weight: bold;
      color: #262626;
      margin:24px 0px 18px 0px ;
      //margin-bottom: 18px;
      &-date{
      font-family: Gilroy;
      font-size: 16px;
      font-weight: 600;
      color: #262626;
      margin-top: 10px;
    }
    }
    &-content{
      padding: 3px;
      margin-left: 12px;
      margin-top: 14px;
      margin-bottom: 15px;
      &-category{
        font-family: Gilroy;
        font-size: 14px;
        font-weight: 600;
        color: #262626;
      }
      &-title{
        font-family: Gilroy;
        font-size: 16px;
        font-weight: bold;
        color: #262626;
        margin-bottom: 4px;
      }
      &-description{
        opacity: 0.5;
        font-family: Gilroy;
        font-size: 14px;
        font-weight: 600;
        color: #262626;
        margin-bottom: 7px;
      }
      &-status{
        border-radius: 16px;
        background-color: #2e7d32;
        // width:220px;
        // padding: 2px 10px 2px 10px;
        color: #fff;
      }
    }
  }
}

.calendar-month-title{
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 600;
  color: #262626;
  width: 160px;
  margin-left: 23px;
}

.calendar-today-button{
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #262626;
  margin:0px 0px 0px 16px;
  cursor: pointer;
}


.status-bar-calendar-main{
  //width: 90px;
  //padding: 5px 10px 5px 10px;
  // text-align: center;
  // border-radius: 4px;
  // box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  // border: solid 1px #e8efee;
  // color:white;
  &-span{
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
    padding: 5px 10px 5px 10px;
    font-family: Gilroy;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    vertical-align: baseline;
    text-align: left;
    border-radius: 4px;
  }
}

.calendar-circular-scroll{
  &-title{
    font-family: Gilroy;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #262626;
  }
  &-circle{
    width: 32px;
    height: 32px;
    margin: 4px 0px 0px 8px;
    //padding: 5px 5px 5px 5px;
    padding-top: 2px;
    border: solid 1px #d2dfde;
    border-radius: 50%;
    text-align: center;
    &-text{
      font-family: Gilroy;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: #262626;
    }
    
  }
}

.direct-coach-icon{
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background-color: #4650ff;
  // display: flex;
  // justify-content: center;
  padding: 3px;
  text-align: center;
  path{
    fill: white;
  }
}


.calendar-header{
  display: flex;
  align-items:center ;
  .ant-row{
    display: flex;
    align-items:center ;
  }
}
.date-fullscreen-cell{
  min-height: 112px;
  border: solid 1px #e8efee;
  &-content{
    display: table-cell;
    vertical-align: bottom;
    position: static;
  width: auto;
  min-height: 96px;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  text-align: left;
  }
}


.more-tile{
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 600;
  color: #262626;
}

// .Earnings polyline{
//   stroke: red !important;
// }

.calendar-circle{
  display: flex;
  justify-content: center;
}

.bottom-navigation-content .bottom-navigation-menu-content{
  cursor: pointer;
}


@media (min-width: 1025px) and (max-width: 1200px) {
  .simple-classes-card{
    margin-left: 0px !important;
    &-upper{
     width: 33% !important;
     margin-left: 16px;
    }
    &-content{
      &-amount{
        width: 30% !important;
        margin-left: 10px !important;
      }
      &-title{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .status-bar-main{
    margin-right: 12px !important;
  }
}

.back-arrow polyline{
  stroke: $themeColor;
}

.back-arrow-disabled polyline{
  stroke: #d3d3d3;
  pointer-events: none;
}

.referrals{
  &-heading{
    font-family: Gilroy;
    font-size: 20px;
    font-weight: 600;
    color: #262626;
    margin-bottom:40px;
  }
  &-content{
    display: flex;
    justify-content: space-between;

    &-container{
      display: flex;
      text-align: center;
      justify-content: center;
      position: relative;
    }
    
    &-tooltip-main{
      width:16px;
      height:16px; 
      background-color:$themeColor;
      border-radius:50%;
      display:flex;
      justify-content:center;
      align-items:center;
      margin-left: 14px;
      cursor: pointer;
      font-weight: bold;
    }
  }
  &-left{
    width: 45%;
  }
  &-right{
    width: 45%;
  }

}



.referrals-content-tooltip{
  background-color: #fff;
  min-width: 250px !important;
  .ant-tooltip-arrow{
    display: none;
  }
  .ant-tooltip-arrow-content{
    background-color: rgba(70, 80, 255, 0.05) !important;
  }
  .ant-tooltip-inner{
    background-color: rgba(70, 80, 255, 0.05) !important;
    box-shadow: rgb(153 153 153 / 37%) 0px 0px 10px 1px !important;
    
  }
  .referrals-content-tooltip-span{
    color:#262626 !important;
  }
}
.coaching-tab{
  .ant-tabs-tab-btn{
    width: 80px;
    font-family: Gilroy;
    font-size: 14px !important;
    font-weight: 600 !important;
    text-align: center;
    color: #262626 !important;
    margin-right: 34px;
    margin-left: 20px;
    
  }
  .ant-tabs-ink-bar {
    background: #1d615a !important;
    width: 120px !important;
    height: 4px !important;
  }
}

.nothing-screen{
  width: 454px;
  margin:auto;
  margin-top: 20%;
  margin-bottom: 20%;
  &-heading{
    font-family: Gilroy;
    font-size: 24px;
    font-weight: bold;
    color: #262626;
    text-align: center;
  }
  &-description{
    font-family: Gilroy;
    font-size: 16px;
    text-align: center;
    color: #262626;
    margin-top: 16px;
    &-span{
      font-weight: bold;
      color: #1d615a;
    }:hover{
      cursor: not-allowed;
    }
    }
   &-button{
     text-align: center;
     padding: 13px 24px;
     margin-top: 24px;
     cursor: pointer;
    &-span{
      // margin: 24px 151px 0 150px;
      padding: 13px 24px;
      border-radius: 2px;
      background-color: #1d615a;
      color: #fff;
      font-family: Gilroy;
      font-size: 16px;
      font-weight: 600;
    }
   } 
}

.coaching-card{

  &-timmer{
    width: 385px;
    cursor: pointer;
  }

  &-component{
    width: 385px;
    height: 216px;
    border: unset !important;   
    .ant-card-body{
      display: none;
    } 
    img{
      width: 385px;
      margin-left: 1px;
    }
    &-timmer{
      height: 48px;
      background-color: #f48432;
      display: flex;
      justify-content: center;
      text-align: center;
      &-span{
        font-family: Gilroy;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: #fff;
        margin: auto;
      }
    }
  }
  &-date{
    font-family: Gilroy;
    font-size: 24px;
    font-weight: bold;
    color: #262626;
    margin-top: 48px;
  }
  &-time{
    font-family: Gilroy;
    font-size: 20px;
    color: #262626;
    margin-bottom: 16px;
    margin-top: 24px;
  }
  &-image{
    position: relative;
    margin-top: 16px; 
    cursor: pointer; 
    padding-right: 24px;
    img{
      //border-radius: 8px !important;
      // opacity: 0.5;
    }
    &-upper{
      position: absolute;
      top: 0;
      margin:19px 0px 0px 23px;
      width: 334px;
      &-title{
        font-family: Gilroy;
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: inherit;
      }
      &-category{
        font-family: Gilroy;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        margin-top: 4px;
      }
    }
    &-lower{
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      &-span{
        font-family: Gilroy;
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        margin-left: 13px;
        &-waitlist{
          font-family: Gilroy;
          font-size: 20px;
          color: #fff;
          margin-left: 16px;
        }
      }

    }
  }
}

.participant-icon path{
  fill: #fff;
}

.edit-stats{
  padding-top: 5%;
  &-heading{
  font-family: Gilroy;
  font-size: 24px;
  font-weight: bold;
  color: #262626;
  }
}

.iconsss{
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: red;
  // display: flex;
  // justify-content: center;
  // flex-direction: row;
  text-align: center;
  // margin: auto;
  &-span{
    display: flex;
    justify-content: center;
    margin: auto;
  }
}

.back-arrow-ots polygon:nth-child(2){
  fill: #ffff;
}
.back-arrow-ots{
  cursor: pointer;
}

.edit-ots{
  display: flex;
  flex-direction: column;
  &-icon-heading{
    font-size: 16px;
    font-weight: normal;
  }
  &-icon-value{
    font-size: 22px;
    font-weight: 600;
  }
  &-divider{
    margin: 10px 0px !important;
  }
}

.edit-detail{
  display: flex;
  padding: 20px 0px;
  &-container{
    width: 60%;
  }
  &-message{
    width: 40%;
    padding: 0px 30px;
  }
  &-heading{
    font-size: 22px;
    font-weight: bold;
    color: #262626;
  }
  &-timing{
    padding: 15px 0px;
  }
  &-timeline{
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-right: 20px;
    font-size: 16px;
    font-weight: 600;
    color: #262626;
  }
  &-timebutton{
    text-decoration: underline;
    cursor: pointer;
  }
  &-time{
    font-size: 16px;
    font-weight: normal;
    color: #262626;
  }
  &-earnings{
    border-radius: 4px;
    border: solid 1px rgba(70, 80, 255, 0.05);
    background-color: rgba(70, 80, 255, 0.05);
    padding: 40px 20px;
    &-heading{
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      color: #262626;
    }
    &-container{
      display: flex;
      justify-content: space-between;
      padding-top: 16px;
    }
    &-name{
      font-size: 18px;
      font-weight: normal;
      color: #262626;
    }
  }
  &-buttons{
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
  }
}

.edit-ots-main{
  padding-top: 30px;
  padding-right: 15%;
  display: flex;
  justify-content: space-between;
}

.edit-card-main{
  width: 100%;
  height: 360px;
}

.edit-card-cover-overlay{
  background-color: #00000082;
  position: absolute;
  opacity: 0.5; 
  width: 100%; 
  height: 360px;
  top: 0;
  margin: -1px 0px 0px 1px;
}

.edit-card-back-arrow{
  position: absolute;
  top: 0px;
  margin: 23px 0px 0px 29px;
}

.coaching-card-image-title{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0px;
  &-heading{
    font-size: 32px;
    font-weight: bold;
    color: #fff;
  }
  &-description{
    font-size: 22px;
    font-weight: 600;
    color: #fff;
  }
}

.flex-wrapper-coaching {
  display: flex;
  flex-wrap: wrap;
  &-item-50 {
    // flex: 50%;
    border-radius: 10px;
    background-color: #ffffff;
  }
}

.card-cover-overlay-coaching{
  background-color: #00000082;
  position: absolute;
  opacity: 0.7;
  width: 385px;
  height: 216px;
  top: 0;
  }
.modal{
  &-heading{
    font-family: Gilroy;
    font-size: 24px;
    font-weight: bold;
    color: #262626;
    padding: 30px 30px 0px 30px;
    display: flex;
  }
  &-description{
    font-size: 16px;
    color: #262626;
    padding: 10px 30px;
    display: flex;
    justify-content: center;
    &-heading{
      font-family: Gilroy;
      font-size: 20px;
      font-weight: bold;
      color: #262626;
    }
    &-datetime{
      font-family: Gilroy;
      font-size: 16px; 
      color: #262626;
      margin-top: 14px;
    }
  }
  &-select{
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
  }
  &-subheading{
    font-size: 20px;
    font-weight: bold;
  }
  &-button{
    width: 150px;
    height: 40px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #262626 !important;
    border: solid 1px #d2dfde !important;
  }
  &-button-cancel{
    width: 150px;
    height: 40px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #262626 !important;
    background-color: #1d615a !important;
    color: #fff !important;
    border: solid 1px #d2dfde !important;
  }
  &-spots{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 26px 0px 26px 0px;
  }
  &-signs{
    font-family: Gilroy;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    color: #262626;
    cursor: pointer;
    
    &-disable{
      color: #A8A8A8 !important;
      pointer-events: none;
    }
    &-amount{
      font-family: Gilroy;
      font-size: 32px;
      font-weight: bold;
      text-align: center;
      color: #262626;
      margin: 0px 40px 0px 40px;
      width:130px;
    }
  }
}

.start-end-time{
  margin-top: 15px;
  font-family: "Gilroy";
  &-input{
    margin-bottom: 5px !important;
  }
  &-heading{
    margin-bottom: 0px !important;
  }
}

.activate-ots-button-new{
  text-shadow: none !important;
  font-weight: 500 !important;
  background-color: #1d615a !important;
  border-color: #1d615a !important;
  // margin-left: 5% !important;
  min-width: 200px;
  padding: 20px !important;
  color: #fff !important;
}

.ots-footer{
  display: flex;
  width: 100%;
  padding-right: 30px; 
  padding-top: 10px;
}

.button-section{
  // width: 30%;
}

.button-section-demo{
  margin-left: 5%;
}

.demo-email-section{
  padding: 0px 30px;
}

.demo-input{
  display: flex;
  padding: 10px 0px;
}

.email-input{
  padding-left: 5px ;
  width: 50%;
}

.ant-form-item .ant-input-number-handler-wrap {
  z-index: 0 !important;
}

.demo-input-wrapper{
  width: 50%;
}

.coaching-card-image-title-iPad{
  top: 50%;
  left: 50%;
  position: absolute;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.coaching-buttons{
  display: flex;
}
.test-home-screen{
  background-color: #262626;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  &-arrow{
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    margin: 5% 0px 0px 5%;
  }
  &-main{
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
  }
  &-title{
    font-family: Gilroy;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }
  &-container{
    width: 60%;
    padding: 20px; 
    display: flex; 
    flex-direction: column;
    text-align: center; 
    justify-content: center; 
  }
  &-box{
    width: 236px;
    height: 203px;
    border-radius: 2px;
    border: solid 1px rgba(255, 255, 255, 0.2);
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 10px;
    &-talk{
      width: 38px;
      height: 38px;
      background-color: #1ab3b5;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px auto;
      &-heading{
        font-family: Gilroy;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        color: #fff;
      }
      &-description{
        font-family: Gilroy;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: #fff;
      }
    }
    &-converse{
      width: 38px;
      height: 38px;
      background-color: #f57f17;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px auto;
    }
  }
  &-test-audio{
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin-top:32px;
    text-decoration: underline;
  }
  &-button{
    margin-top: 32px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    &-span{
      width: 155px;
      height: 48px !important;
      border-radius: 4px !important;
      background-color: #1d615a !important;
      border-color: #1d615a !important;
      font-size: 16px !important;
      font-weight: 600 !important;
      color: #fff !important;
      // padding: 1px 24px 13px 24px;
     
    }
  }
}

.talk-icon path{
  fill: #fff !important;
}

.talk-icon-tag{
  margin-top: 4px;
  margin-right: 5px;
  path{
    fill: #fff;
  }
}

.converse-icon-tag {
  margin-right: 7px;
  path{
    fill: #fff;
  }
}

.converse-icon path{
  fill: #fff !important;
}

.test-audio-screen{
  background-color: #262626;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  &-main{
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
  }
  &-title{
    font-family: Gilroy;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }
  &-container{
    width: 60%;
    padding: 20px; 
    display: flex; 
    flex-direction: column;
    text-align: center; 
    justify-content: center; 
    margin: auto;
  }
  &-box{
    width: 367px;
    height:  232px;
    border-radius: 2px;
    border: solid 1px rgba(255, 255, 255, 0.2);
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 10px;
    &-talk{
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px auto;
      &-heading{
        font-family: Gilroy;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: #fff;
      }
      &-description{
        font-family: Gilroy;
        font-size: 16px;
        text-align: center;
        color: #fff;
      }
      &-test{
        width: 80px;
        height: 48px;
        cursor: pointer;
        padding: 13px 23px 13px 25px;
        border-radius: 4px;
        border: solid 1px #d2dfde;
        color: #fff;
        margin: 24px auto 0px auto; 
        display: flex;
        justify-content: center;
      }
      &-confirm{
        display: flex;
        justify-content: center;
        flex-direction: column; 
        &-title{
          font-family: Gilroy;
          font-size: 16px;
          text-align: center;
          color: #fff;
          margin-bottom: 24px;
          margin-top: 16px;
        }
        &-button{
          display: flex;
          justify-content: center;
          &-yes{
            width: 72px;
            height: 48px;
            padding: 13px 20px;
            border-radius: 4px;
            border: solid 1px #d2dfde;
            color: #fff;
            cursor: pointer; 
          }
          &-no{
            width: 72px;
            height: 48px;
            padding: 13px 20px;
            border-radius: 4px;
            border: solid 1px #d2dfde;
            color: #fff;
            margin-left:20px;
            cursor: pointer; 
          }
        }
      }
    }
    &-converse{
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px auto;
      position: relative;
    }
    &-success{
      display: flex;
      justify-content: center;
      flex-direction: column;
    
      &-title{
        font-size: 16px;
        text-align: center;
        color: #fff;
        margin-top: 20px;
      }
    }
  }
  &-test-audio{
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin-top:32px;
    text-decoration: underline;
  }
  &-button{
    margin-top: 56px;
    cursor: pointer;
    &-span{
      border-radius: 4px;
      background-color: #1d615a;
      font-family: Gilroy;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: #fff;
      padding: 13px 24px 13px 24px;
     
    }
  }
}

.audio-screen-back-arrow polygon{
  fill: #fff !important;
}

.audio-screen-back-arrow-span{
  position: absolute;
  top: 0;
  left: 0; 
  cursor: pointer;
  margin:5% 0px 0px 5%
}

.waiting-screen-index{
  background-color: #262626;
  width: 100vw;
  height: 100vh;
  
}
.waiting-screen{
  display: flex;
  justify-content: center;
  // text-align: center;
  &-text{
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #fff;
    margin: auto;
  }
  &-bottom{
    background-color: #262626;
    width: 100vw;
    height: 71px;
    border-top: solid 1px rgba(255, 255, 255, 0.1);
    //1px rgba(255, 255, 255, 0.1);;
    position: fixed;
    display: flex;
    bottom: 0;
    &-left{
      width: 30%;
    }
    &-middle{
      width: 40%;
      display: flex;
      justify-content: center;
      margin: auto;
      &-wrap{
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
      }
      &-icons{
        width: 38px;
        height: 38px;
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin: auto;
        //margin: 10px auto;
      }
      &-text{
        font-family: Gilroy;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        color: #fff;
        margin-top: 4px;
      }

    }
    &-right{
      width: 30%;
      display: flex;
      justify-content: right;
      margin: auto;
      &-section{
        display: flex;
        justify-content: center;
        margin-top: auto;
        margin-bottom: auto;
        &-button{
          width: 117px;
          height: 48px !important;
          border-radius: 4px !important;
          display: flex !important;
          justify-content: center;
          cursor: pointer;
          margin-left: 19px;
          margin-right: 9px;
          color: #fff !important;
          font-size: 16px !important;
          font-weight: 600 !important;
          &-span{
            font-family: Gilroy;
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            color: #fff;
            margin: auto;
          }
        }
        &-participant{
          margin-top: 2px;
          &-count{
            font-family: Gilroy;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            color: #fff;
            margin-left: 8px;
            margin-top: 3px;
          }
          &-line{
            font-family: Gilroy;
            font-size: 23px;
            font-weight: 600;
            text-align: center;
            color: #fff;
            margin-left: 18px;
            margin-top: 1%;
            margin-bottom: auto;
          }
        }
        &-duration{
          font-family: Gilroy;
          font-size: 14px;
          font-weight: 600;
          text-align: center;
          color: #fff;
          margin-left: 15px;
          &-cell{
            display: flex;
            justify-content: center;
            margin-top: auto;
            margin-bottom: auto;
          }
        }
      }
    }

  }
  &-converse-icon, &-message-icon{
    margin-top: 8px;
  }
  &-converse-icon-black{
    margin-top: 5px;
    path{
      fill: #262626;
    }
  } 
  &-converse-icon-white{
    margin-top: 5px;
    path{
      fill: #fff;
    }
  }
  &-talk-icon-white{
    margin-top: 5px;
    path{
      fill: #fff !important;
    }
  } 
  &-talk-icon-black{
    margin-top: 5px;
    path{
      fill: #262626 !important;
    }
  } 
  &-video{
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // margin: 0px 0px 5px 5px;
    &-overlay{
      height: 100%;
      width: 100%;
      display: none;
      // display: flex;
      // flex-direction: column;
      position: absolute;
      top:0;
      background-color: rgba(0, 0, 0, 0.5) ;
      &-top{
        width: 100%;
        //height: 100%;
        //padding: 12px;
        display: flex;
        justify-content: space-between;
        margin: auto;
        &-text-wrap{
          display: flex;
          flex-direction: column;
          margin: auto auto auto 16px;
        }
        &-text-title{
          font-family: Gilroy;
          font-size: 24px;
          font-weight: bold;
          color: #fff;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 324px;
        }
        &-text-description{
          font-family: Gilroy;
          font-size: 16px;
          font-weight: 600;
          color: #fff;
        }
        &-icon{
          margin:10px 16px auto auto;
          cursor: pointer;
        }
      }
      &-middle{
        width: 100%;
        height: 100%;
        margin-bottom: 14px;
        &-wrap{
          display:flex;
          justify-content:space-between;
          height:100%;
        }
      }
      &-bottom{
        width: 100%;
        height: 10px;
        text-align: end;
        //height:100%;
        // display: flex;
        // justify-content: right;
        &-span{
          font-family: Gilroy;
          font-size: 14px;
          font-weight: 600;
          text-align: right;
          color: #fff;
          margin-right: 16px;
          margin-left: auto;
        }
      }
    }
  }
}

.waiting-screen-index-background{
  display: flex;
  justify-content: center;
  height: calc(100vh - 71px) 
}


.waiting-screen-minimizie-icon path{
  fill: #fff !important;
}

.waiting-screen-video-overlay-ipad{
  display: flex !important;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  top:0;
  background-color: rgba(0, 0, 0, 0.5) ;
 }
 .waiting-screen-video-duration-ipad{
   display: none;
 }
.waiting-screen-video:hover{
  .waiting-screen-video-overlay{
   display: flex !important;
   flex-direction: column;
  }
  .waiting-screen-video-duration{
    display: none;
  }
}

.collapsed-footer-pause-icon path{
  fill: #fff !important;
}
.waiting-screen-participant-icon {
  margin-top: 4px;
  path{
    fill: #fff !important;
   
  }
}

.waiting-video-player{
  video{
    box-shadow: unset !important;
  }
}

.collapsed-footer{
  &-video{
    &-player{
      border-radius: 2px;
      video{
        box-shadow: unset !important;
        border-radius: 2px;
      }
    }
  }
  
  &-replay{
    margin: auto 0px auto 20px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    svg{
      width: 21px;
      height: 24px;
    }
    
    &-icon{
      path {
        fill: #fff !important;
      }
      &-disabled{
        path{
          fill: #d3d3d3 !important;
          pointer-events: none;
        }
      }
    }
  }
  &-forward{
    margin: auto 0px auto 20px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    svg{
      width: 21px;
      height: 27px;
    }
    &-icon{
      path, polygon{
        fill: #fff !important;
      }
    }
  }
  &-play{
    margin: auto 0px auto 25px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    svg{
      height: 28px;
      width: 28px;
    }
  }
  &-pause{
    margin: auto 0px auto 20px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    width:33px;
    svg{
      height: 20px;
      width: 20px;
    }
  }
  &-duration{
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    justify-content: center;
    margin: auto 0px auto 20px;
  }
}

.collapsed-footer-video{
  display: flex;
  &-wrapper{
    display: flex;
    &-icons{
        display: flex;
        justify-content: center;
        margin: auto;
    }
  }
}

.collapsed-footer-video-overlays{
  position: absolute;
  top: 0;
  left:0;
  width: 85px;
  height: 48px;
  display: none;
  border-radius: 2px;
  cursor: pointer;
}
.waiting-screen-video-collapsed{
  position: relative;
  display: flex;
  margin: 10px;
  &-wrapper{
    display: flex;
  }
}
.waiting-screen-video-collapsed:hover{
  .collapsed-footer-video-overlays{
    background-color: rgba(0, 0, 0, 0.5);  ;
    display: flex !important;
    justify-content: center;
    &-icon{
      margin: auto;
      cursor: pointer;
    }
  }
}

.collapsed-footer-video-overlays-icon path{
  fill: #fff !important;
}

.player-slider{
  width: 90%;
  .ant-slider-track{
    background-color: #fff;
  } 
  .ant-slider-handle {
    border: 2px solid #fff !important;
    height: 8px !important;
    width: 8px !important;
    margin-top: -2px !important;
  }
  .ant-slider-rail{
    background-color: #262626;
    opacity: 0.5;
  }
  &:hover {
    .ant-slider-rail {
      background-color: #262626 !important;
      opacity: 0.5;
    }
    .ant-slider-track {
      background-color: #fff !important;
    }
    // .ant-slider-handle {
    //   border-color: unset !important  ;
    // }
  }
}

.middle-icon{
  margin-left: 56px; 
  margin-right: 56px;
}

.countdown-overtime{
  color: red;
}

.notHoverStudentVideoTag{
  display: none;
}

.hoverStudentVideoTag{
  top: 50%;
  left: 50%;
  position: absolute;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
  background-color: #262626;
  border-radius: 15px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  cursor: default;
}

.hover-video-tag{
  padding: 3px 10px;
}

.zoom-dialog-modal{
  &-left{
    width: 75%;
    border: solid 2px #fff;
    border-radius: 5px;
    position: relative;
    display: flex;
    &-close{
        .popupover-cross-icon{
          fill: black !important;
      }
    }
    &-talk{
      position: absolute;
      bottom: 0;
      margin:0px 0px 16px 16px ;
      &-span{
        font-family: Gilroy;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        margin-left: 19px;
      }
    }
    &-mic{
      path{
        fill: #fff !important;
      }
    }
  }
  &-right{
    width: 25%;
    background-color: #fff;
    position: relative;
    border-radius: 8px;
    &-name{
      height:50px;
      border-bottom: solid 1px #e8efee;
      display: flex;
      &-span{
        font-family: Gilroy;
        font-size: 20px;
        font-weight: bold;
        color: #262626;
        margin: auto 16px;
      }
    }
    &-fix{
      height: 48px;
      width: 100%;
      border-top: solid 1px #e8efee;
      display: flex;
      bottom: 0;
      justify-content: center;
      position: absolute;
      &-span{
        font-family: Gilroy;
        font-size: 16px;
        font-weight: 600;
        color: #262626;
        margin: auto;
      }
    }
  }
}

.zoom-dialog-modal-right-fix-help{
  margin: auto;
  path{
    fill: #d2dfde !important
  }
}

.test-home-screen-arrow-icon{
  polygon{
    fill: #fff !important;
  }
}

.waiting-screen-video-duration{
  position: absolute;
  bottom: 0;
  margin-bottom: 32px;
}

.create-direct-coach{
  display: flex;
  height: 100vh;
  &-left{
    width:50%; 
    background-color: #1d615a;
    display: flex;
    &-heading{
      width: 434px;
      margin:auto ;
    }
  }
  &-right{
    width:50%; 
    background-color: #fff;
    position: relative;
    display: flex;
    &-bottom{
      position: fixed;bottom: 0;
      width: 50%;
    }
  }
  &-logo{
    padding:16px 0px 0px 16px;
  }
}

.direct-coach-heading-component{
  font-family: Gilroy;
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  line-height: normal;
}


.dc-class-home{
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 396px;
  margin: auto;
  &-input{
    width: 396px;
    .ant-input-affix-wrapper{
      height: 40px !important;
      border-radius: 24px !important;
      border: solid 1px #d2dfde !important;
      background-color: #fff !important;
    }
    .ant-input-group-addon{
      display: none;
    }
    .ant-input::placeholder{
      font-size: 16px !important;
      font-weight: 600 !important;
      color: #262626 !important;
      opacity: 0.5;
    }
  }
  &-card{
    margin-top: 7px;
    width: 100%;
    cursor: pointer;
  }
  &-card-row{
    padding: 0px 5px;
  }
  &-card-col{
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    //border: solid 1px #e8efee;
    background-color: #fff;
    width: 100%;
    &-info{
      display: flex;
      flex-direction: column;
      justify-content: center; 
    }
    &-wrapper{
      display: flex;
    }
    &-title{
      font-size: 14px;
      font-weight: 600;
      color: #262626;
      margin-left: 16px;
    }
    &-description{
      font-size: 14px;
      color: #262626;
      margin-left: 16px;
    }
  }
  &-no-class{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
    &-span{
      font-size: 16px;
      font-weight: bold;
      letter-spacing: normal;
      color: #262626;
      margin: auto;
      opacity: 0.5;
    }
  }
}


.dc-bottom-bar{
  height: 72px;
  width: 100%;
  background-color: #fff;
  &-wrapper{
    display: flex;
    justify-content: space-between;
    &-prev{
      margin-top:3%;
      margin-left: 30px;
      &-button{
        font-size: 16px !important;
        font-weight: 600 !important;
        color: #262626 !important; //#262626;
        border: 1px solid #fff !important;
      }
      .ant-btn{
        box-shadow: unset !important;
      }
    }
    &-next{
      margin-top:2%;
      margin-right: 30px;
      &-button{
        width: 102px !important;
        height: 48px !important;
        border-radius: 4px !important;
        background-color: #1d615a !important;
        color:#fff !important;
      }
      .ant-btn{
        border: 1px solid #fff !important;
        border-color: unset !important;
      }
    }
  }
}

.dc-progress-bar{
  .ant-progress-line {
    height: 8px !important;
  }
  .ant-progress-text{
    display: none !important;
  }
  .ant-progress-bg{
    height: 4px !important;
    background-color: $themeColor !important;
    border-radius: unset !important;
  }
  .ant-progress-inner{
    margin-top: var(--demo_margin) !important;
    background-color: #e8efee !important;
    border-radius: unset !important;
  }
  .ant-progress-show-info .ant-progress-outer {
    padding-right: unset !important;
  }
}

.dc-class-home-card-container{
  margin-top: 32px;
  height: calc(100vh - 200px);
  overflow-y: auto;
  padding-bottom: 20px;
}

.direct-coach-description-component{
  font-family: Gilroy;
  font-size: 16px;
  color: #fff;
  margin-top: 12px;
}

.direct-coach-student{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  &-count{
    display: flex;
    justify-content: center;
    text-align: center;
    margin: auto;
    word-spacing: -5px;
    &-minus{
      margin: auto;
      cursor: pointer;
      &-disabled{
        margin: auto;
        cursor: not-allowed;
        polygon{
          fill: #d3d3d3 !important;
        }
      }
    }
    &-span{
      font-size: 32px;
      font-weight: bold;
      color: #262626;
      margin-left: 56px;
      margin-right: 56px;
      cursor: pointer;
      width:82px;
    }
    &-plus{
      margin: auto;
      cursor: pointer;
      &-disabled{
        margin: auto;
        cursor: not-allowed;
        polygon{
          fill: #d2d2d2 !important;
        }
      }
    }
  }
  &-values{
    display: flex;
    margin: auto;
    &-square{
      width: 72px;
      height: 72px;
      border-radius: 4px;
      border: solid 1px #d2dfde;
      display: flex;
      margin-top: 48px;
      margin-left: 16px;
      cursor: pointer;
      &-span{
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        color: #262626;
        margin: auto;
        word-spacing: -5px;
      }
    }
    .selected{
      border: 1px solid $themeColor !important;
    }
  }
}
.message-textarea{
  padding: 10px 30px 0px 30px;
}

.message-container{
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc !important;
  border-radius: 4px;
  resize: none;
}
.message-modal-heading{
  font-size: 24px;
  font-weight: bold;
  color: #262626;
  padding: 30px 30px 0px 30px;
  display: flex;
  justify-content: center;
}

.message-modal-button{
  padding: 10px 30px 0px 30px;
  display: flex;
}

.message-modal-button-send{
  text-shadow: none !important;
  height: 40px !important;
  font-weight: 500 !important;
  background-color: #1d615a !important;
  border-color: #1d615a !important;
  color: #fff !important;
}

.direct-coach-cost{
  display: flex;
  flex-direction: column;
  justify-content: center;
  // margin-top: 10%;
  margin: auto;
  &-earnings{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 60%;
    margin: auto;
    margin-top: 72px;
    &-title{
      font-size: 20px;
      font-weight: bold;
      color: #262626;
      margin-bottom: 24px;
    }
    &-wrapper{
      display: flex;
      justify-content: space-between;
      &-span{
        font-size: 20px;
        color: #262626;
      }
    }
  }
  &-divider{
    width: 100%;
    height: 1px;
    border-top: solid 1px #e8efee;
    margin-top: 35px;
  }
}

.direct-coach-confirm{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  &-card{
    display: flex;
    justify-content: center;
    position: relative;
    width:395px;
    height:216px;
    margin: auto;
    &-component{
      width:395px;
      height:216px;
      border-radius: 8px;
    }
    &-overlay{
      width:395px;
      height:216px;
      border-radius: 8px;
      background-color: #262626;
      opacity: 0.2;
      position: absolute;
    }
    &-desc{
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      margin-top: 19px;
      margin-left: 23px;
      &-span-title{
        font-size: 20px;
        font-weight: bold;
        color: #fff;
      }
      &-span-desc{
        font-size: 16px;
        font-weight: 600;
        color: #fff;
      }
    }
    .ant-card-bordered{
      border: transparent !important;
    }
  }
  &-detail{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 395px;
    margin: auto;
    &-divider{
      width: 100%;
      height: 1px;
      border-top: solid 1px #e8efee;
      margin-top: 24px;
    }
    &-datetime{
      margin-top: 24px;
      &-span{
        font-size: 20px;
        color: #262626;
      }
    }
    &-wrapper{
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      &-span{
        font-size: 20px;
        color: #262626;
      }
    }
  }
}

.selected {
  .dc-class-home-card-col {
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #1d615a;
  }
}

.direct-coach-date-time{
  height: 89vh;
  padding: 52px 142px;
  overflow-y: auto;
  .ant-picker-calendar-full{
    //height: 48vh;
  }
  .ant-picker-calendar-header{
    display: none;
  }
  .date-fullcell-directcoach{
    min-height: 34px !important;
    // padding: 7px 0px 0 !important;
    font-size: 13px ;
    font-weight: 600;   
    display: flex;
    justify-content: center;
    align-items: center; 
  }
  .ant-picker-cell-selected{
    .create-direct-coach-calendar-cell{
      outline: none !important;
    }
    .date-fullcell-directcoach{
    border: 1px solid #1d615a;
    width: 34px;
    border-radius: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    // color: #fff;
    }
  }
  .ant-picker-body{
    text-align: center;
  }
  .ant-picker-cell{
    // padding: 8px 0px 0px 0px !important;
    .ant-picker-calendar-date{
      height: 35px !important;
      min-width: 35px !important;
      width: 35px !important;
      border: transparent !important;
      display: flex !important;
      border-radius: 25px !important;
      justify-content: center;
      // padding: 7px 8px 0 !important;
      background-color: #fff;
      font-family: Gilroy;
      font-size: 14px ;
      font-weight: 600;
    }
  }
  thead{
    display: contents !important;
  
    tr{
      border-top: 1px solid #e8efee;
      border-bottom: 1px solid #e8efee;
      
    }
    th{
      opacity: 0.5;
      font-size: 13px;
      font-weight: 600;
      color: #262626;
      padding: 10px 12px 10px 10px !important;
    }
  }
  tbody{
    td{
      padding: 7px 0px 0 !important;
    }
  }
  .directcoach{
    &-calendar{
      &-header{
        display: flex;
        justify-content: center;
      }
      &-month-title{
        font-size: 13px;
        font-weight: 600;
        width: 40px;
        height: 40px;
        margin-left: 23px;
        border-radius: 50%;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
      }
    }
    &-minus-icon{
      border-radius: 50%;
      polygon{
       fill: #fff;
     }
    }
    &-plus-icon{
      // width: 24px;
      border-radius: 50%;
      polygon{
       fill: #fff;
     }
    }
    &-plus-icon-container{
      width: 24px;
      height: 24px;
      // background-color: aqua;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
    &-time-section-input{
      display: flex;
      justify-content: center;
      align-items: center;
      // margin-top: 20px;
    }
    &-input-container{
      width: 150px;
      display: flex;
      flex-direction: column;
    }
    &-time-input-title{
      color: #262626;
      font-size: 16px;
      font-weight: 600;
      padding: 0px 16px;
    }
    &-time-input{
      padding-bottom: 0px;
      width: auto;
      border-bottom: none;
      font-size: 20px;
      font-weight: 600;
      color: #262626;
    }
    &-meridiem{
      text-align: center;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      color: #262626;
    }
    &-divider{
      margin: 24px 0px;
    }
    &-horizontal-time{
      display: flex;
      overflow: scroll;
      height: 100%;
      width: calc(100vw - 30px);
      color: #d3d3d3;
      &-input{
        width: 80px;
        height: auto;
        display: flex;
        justify-content: center;
      }
      &-container{
        display: flex;
        flex-direction: column;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
      }
      &-merdiem{
        margin: auto;
      }
      .selected{
        color: black !important;
      }
      .first-index{
        padding-left: 40%;
      }
    }
  }
}

.direct-coach-calendar{
  .ant-picker-cell-disabled{
    color: #d3d3d3;
  }
}

.demo-direct-coach{
  width: 80%;
  display: flex;  
  margin: auto;
  flex-direction: column;
  &-wrapper{
    // width:60%;
    // margin: auto;
    // background-color: blue;
  }

  &-form{
    .ant-form-item-control-input-content{
      display: flex;
      width: 100% !important;
    }
    .demo-direct-coach-input{
      margin: auto;
      text-align: left;
      border-radius: 8px !important;
      border: solid 1px #d2dfde !important;
      height: 46px;
      box-shadow:0 0 5px 2px rgba(0,0,0,.05);
      width: 90%;
    }
    .ant-form-item-explain-connected, .ant-form-item-explain-error  {
      width: 90%;
      margin: auto;
    }
    .ant-col-18{
      max-width: unset !important;
    }
  }

}